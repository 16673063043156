@import "../../../styles/variables";

.shelter-section {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 60px;
  > .controller-bar {
    width: 260px;
    max-width: 20%;
    .controller {
      margin-bottom: 40px;
      .step {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 18px;
        span {
          margin-right: 8px;
        }
      }
      .buttonbar {
        max-height: 250px;
        overflow: auto;
        > button {
          display: block;
          margin-bottom: 10px;
          border: 1px solid $lightPink;
          padding: 12px;
          width: 100%;
          font-weight: 600;
          transition: all .3s ease;
          text-align: left;
          &:hover, &.-active {
            background: $lightPink;
          }
          .name {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 600;
          }
          .info {
            font-weight: 300;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
      .select {
        margin-bottom: 10px;
        border: 1px solid $pink;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .submit {
      margin-bottom: 40px;
      > button {
        display: block;
        margin-bottom: 10px;
        border: 1px solid $black;
        border-radius: 4px;
        padding: 12px;
        width: 100%;
        background: $black;
        color: $white;
        font-weight: 600;
        transition: all .3s ease;
        text-align: center;
        &:not(:disabled):hover,
        &:not(:disabled).-active {
          border: 1px solid $pink;
          background: transparent;
          color: $black;
        }
        &:disabled {
          opacity: .5;
          cursor: not-allowed;
        }
      }
    }
  }
  > .shelter-result {
    position: relative;
    width: 900px;
    max-width: 75%;
    .title {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
    }
    .note {
      button {
        color: $pink;
      }
    }
    .map {
      margin-bottom: 20px;
    }
    .result {
      min-height: 480px;
      transition: all .3s ease;
      &.-show {
        visibility: visible;
        opacity: 1;
      }
      &.-hide {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}