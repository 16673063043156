@import "../../../styles/variables";

.custom-checkbox {
    cursor: pointer;
    > input {
        display: none;
    }
    > .icon {
        display: inline-flex;
        vertical-align: middle;
        margin-right: 8px;
        border: 1px solid $black;
        border-radius: 2px;
        width: 16px;
        height: 16px;
    }
    > .icon:after {
        content: "";
        display: none;
        margin: auto;
        border-left: 1px solid;
        border-bottom: 1px solid;
        border-color: $white;
        width: 6px;
        height: 4px;
        transform: rotate(-45deg);
        
    }
    span {
        display: inline-block;
        vertical-align: middle;
    }
    > input:checked + .icon {
        background: $yellow;
    }
    > input:checked + .icon:after {
        display: block;
    }
}
