@import "../../../styles/variables";

.custom-dropdown {
    display: inline-block;
    position: relative;
    &.round {
        > .selected {
            border-radius: 4px;
        }
    }
    > .selected {
        position: relative;
        padding: 8px 30px 8px 16px;
        border: 1px solid $black;
        min-width: 85px;
        height: 32px;
        line-height: 1em;
        text-align: left;
        cursor: pointer;
        transition: all .3s ease;
    }
    > .selected i {
        position: absolute;
        right: 4px;
        top: 2px;
        font-size: 28px;
        pointer-events: none;
    }
    > .selected:hover,
    > .selected.-active {
        background: $black;
        color: $white;
    }
    > .selected.-active i {
        transform: rotate(180deg);
    }

    > .list {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 100%;
        right: 0;
        border: 1px solid $black;
        background: $white;
        transform: translateY(10px);
        transition: all .3s ease;
        z-index: 2;
    }
    > .list > li {
        min-width: 160px;
        height: 36px;
    }
    > .list > li:not(:last-child) {
        border-bottom: 1px solid $black;
    }
    > .list > li button,
    > .list > li a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        white-space: nowrap;
        font-weight: normal;
        text-align: left;
    }
    > .list > li button:hover,
    > .list > li a:hover {
        background: $black;
        color: $white;
        cursor: pointer;
    }
    > .list.-active {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
    }
}