@import "../../../styles/variables";
@keyframes jump {
    0% {
        transform: scale(1, .85);
    }
    15% {
        transform: translateY(-5%) scale(.85, 1.1)
    }
    30% {
        transform: translateY(-50%) scale(.9, 1.05)
    }
    50% {
        transform: translateY(-60%) scale(1)
    }
    70% {
        transform: translateY(-50%) scale(1)
    }
    90% {
        transform: scale(1, 1);
    }
    95% {
        transform: scale(1, .85);
    }
    100% {
        transform: scale(1, .85);
    }
}
.svi-charttitle {
	margin-bottom: 20px;
	border-bottom: 4px solid $blue;
	padding: 15px 0;
	text-align: center;
	.title {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 28px;
	}
	.subtitle {
		font-size: 18px;
	}
}
.svi-chart {
	.chart-controller {
		.basic {
			margin-bottom: 50px;
		}
		.step {
			margin-bottom: 20px;
			> .title {
				margin-bottom: 10px;
				font-size: 18px;
				font-weight: 600;
				.en {
					font-family: "Roboto";
				}
			}
			> .buttonbar {
				a {
					display: block;
					margin-bottom: 10px;
					border: 1px solid $blue;
					border-radius: 4px;
					padding: 12px;
					width: 100%;
					font-weight: 600;
					transition: all .3s ease;
					text-align: center;
					&:hover, &.-active {
						background: $blue;
						color: $white;
					}
				}
			}
			> .info {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: 400;
				line-height: 1.5;
				> .reset {
					color: $blue;
					font-family: "Roboto";
					i {
						margin-right: 4px;
					}
				}
			}
			> .select {
				margin-bottom: 10px;
				border: 1px solid $blue;
				border-radius: 4px;
				font-weight: 600;
			}
		}
		.submit {
			margin-bottom: 10px;
			text-align: center;
			button {
				border-radius: 4px;
				padding: 12px;
				width: 160px;
				height: 60px;
				background: #505050;
				color: $white;
				font-size: 20px;
				font-weight: 600;
				span {
					vertical-align: middle;
				}
				&:disabled {
					opacity: .5;
					cursor: not-allowed;
				}
				&.-loading {
					&:after {
						content: "";
						display: inline-block;
						vertical-align: middle;
						width: 8px;
						height: 8px;
						margin-left: 8px;
						// border: 4px solid $white;
						border-radius: 50%;
						background: $white;
						animation: jump .5s linear infinite;
					}
				}
			}
		}
	}
	.chart-result {
		.heading {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 14px;
			.title {
				font-size: 20px;
				font-weight: 600;
			}
		}
		.chart {
			position: relative;
			margin-bottom: 20px;
			min-height: 400px;
			&.-show {
				display: block;
			}
			&.-hide {
				display: none;
			}
			svg {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		.info {
			margin-bottom: 10px;
			> .title {
				padding-top: 10px;
				margin-bottom: 10px;
				font-size: 18px;
				font-weight: 600;
			}
			> .content {
				padding-right: 12px;
				line-height: 1.5;
			}
		}
		.origin {
			margin-bottom: 20px;
			> .title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 10px;
				.text {
					font-size: 18px;
					font-weight: 600;
				}
			}
			> .table {
				border: 1px solid $black;
				min-height: 80px;
				max-height: 400px;
				overflow: auto;
			}
			> .remark {
				margin-top: 12px;
				font-size: 14px;
				a {
					text-decoration: underline;
					&:hover {
						color: $blue;
					}
				}
			}
		}
	}
}
