@import "../../../styles/variables";

.custom-input {
	> label {
		display: block;
		margin-bottom: 5px;
    }
    > span {
        display: inline-block;
        margin-top: 8px;
        font-size: 14px;
        letter-spacing: 2px;
    }

	> input {
		display: block;
		border: 0;
		border-radius: 2px;
		padding: 8px 12px;
        width: 100%;
        height: 48px;
        font-size: 16px;
    }
    > .msg {
        margin-top: 10px;
        &.-danger {
            color: $danger;
        }
    }
    > input.-success {
        box-shadow: inset 0px 0px 0px 1px $success;
    }
    > input.-warning {
        box-shadow: inset 0px 0px 0px 1px $warning;
    }
    > input.-danger {
        box-shadow: inset 0px 0px 0px 1px $danger;
    }

}