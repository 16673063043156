@import "variables";

.form {
  .formitem {
    margin-bottom: 20px;
  }
  .formitem.-left {
    text-align: left;
  }
  .formitem.-right {
    text-align: right;
  }
  .formitem.-center {
    text-align: center;
  }
  .formitem > .description {
    margin-bottom: 36px;
  }
  .formitem > .submit {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .formitem > span {
    display: inline-block;
    margin-top: 8px;
    font-size: 14px;
    letter-spacing: 2px;
  }
  .formitem > textarea {
    display: block;
    border: 0;
    border-radius: 2px;
    padding: 8px 12px;
    width: 100%;
    min-height: 160px;
    font-size: 15px;
  }
  .formfooter {
    margin-top: 40px;
  }
  .submit {
    height: 48px;
  }
}
.form-msg {
  border: 1px solid #afafaf;
  padding: 10px 15px;
  padding-right: 25px;
  background: $white;
  .title {
    display: block;
    text-align: left;
  }
  .article {
    margin-top: 10px;
    margin-bottom: 20px;
    max-height: 110px;
    line-height: 1.25em;
    letter-spacing: 1px;
    color: #505050;
    text-align: left;
    overflow-y: auto;
  }
}
