@import "../../../styles/variables";

.limit-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($black, 0.8);
  color: $white;
  z-index: 99;
  @include for-size(mobile) {
    display: flex;
  }
  @include for-size(tablet-more) {
    display: none;
  }
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    color: $white;
    font-size: 36px;
  }
}
