@import "../../../styles/variables";

.article-item {
  margin-bottom: 40px;
  > .image {
    display: block;
    position: relative;
    width: 100%;
    height: 260px;
    overflow: hidden;
    @include for-size(phone) {
      margin-bottom: 10px;
    }
    @include for-size(tablet) {
      margin-bottom: 24px;
    }
  }
  > .image img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  > .image:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20%;
    height: 4px;
    background: $green;
    transition: all 0.3s ease;
  }
  > .image.-green:before {
    background: $green;
  }
  > .image.-yellow:before {
    background: $yellow;
  }
  > .image.-blue:before {
    background: $blue;
  }
  > .image.-red:before {
    background: $red;
  }
  > .image.-pink:before {
    background: $pink;
  }
  > .image:hover:before {
    width: 100%;
  }
  > .content {
    // display: flex;
    // @include for-size(phone) {
    //     flex-wrap: wrap;
    // }
    // @include for-size(tablet) {
    //     flex-wrap: nowrap;
    // }
  }
  > .content > .datemonth {
    display: block;
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-weight: 600;
    //     @include for-size(phone) {
    //         margin-bottom: 10px;
    //     }
    //     @include for-size(tablet) {
    //         padding-left: 8px;
    //         padding-right: 6px;
    //         margin-bottom: 0;
    //         margin-right: 24px;
    //     }
    // }
    // > .content > .datemonth .month {
    //     letter-spacing: 1px;
    //     @include for-size(phone) {
    //         display: inline-block;
    //         vertical-align: middle;
    //         margin-right: 4px;
    //         font-weight: 400;
    //         text-align: left;
    //     }
    //     @include for-size(tablet) {
    //         display: block;
    //         margin-right: 0;
    //         margin-bottom: 4px;
    //         font-weight: 800;
    //         text-align: center;
    //     }
  }
  // > .content > .datemonth .date {
  //     letter-spacing: 1px;
  //     @include for-size(phone) {
  //         display: inline-block;
  //         vertical-align: middle;
  //         text-align: left;
  //         font-weight: 400;
  //     }
  //     @include for-size(tablet) {
  //         display: block;
  //         text-align: center;
  //         font-weight: 800;
  //     }
  // }
  // > .content > .article {
  //     overflow: hidden;
  // }
  > .content > .title {
    display: block;
    font-size: 18px;
    font-weight: 800;
    @include for-size(phone) {
      margin-bottom: 10px;
    }
    @include for-size(tablet) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }
  }
  > .content > .author {
    margin-bottom: 10px;
    font-size: 16px;
  }
  > .content > .author label {
    margin-right: 12px;
  }
  > .content > .sort {
    margin-bottom: 15px;
    font-size: 16px;
  }
  > .content > .sort .tag {
    margin-right: 8px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  > .content > .sort label {
    margin-right: 12px;
  }
  > .content > .summary {
    display: block;
    line-height: 1.5em;
    font-weight: 300;
  }
  > .content > .read {
    font-size: 14px;
  }
}
