@import "../../../styles/variables";

.custom-radio {
  > input {
    display: none;
  }
  > .label {
    cursor: pointer;
  }
  > .label > .icon {
    display: inline-flex;
    vertical-align: middle;
    margin-right: 8px;
    border: 1px solid $black;
    border-radius: 50%;
    width: 21px;
    height: 21px;
  }
  > .label > .icon:after {
    content: "";
    display: none;
    margin: auto;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background: $gray;
  }
  > .label > .text {
    display: inline-block;
    vertical-align: middle;
  }
  > input.-checked + .label > .icon:after {
    display: block;
  }
  &.green {
    > .label > .icon:after {
      background: $green;
    }
  }
  &.blue {
    > .label > .icon:after {
      background: $blue;
    }
  }
  &.pink {
    > .label > .icon:after {
      background: $pink;
    }
  }
  &.yellow {
    > .label > .icon:after {
      background: $yellow;
    }
  }
}
