@import "../../../styles/variables";
.subject-page {
  .subject-heading {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center center;
    text-align: center;
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 6px;
      background: $green;
      z-index: 1;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background: rgba($black, .33);
      z-index: 0;
    }
    > .mainheading {
      margin-bottom: 12px;
      font-weight: 600;
      color: $white;
      z-index: 2;
    }
    > .subheading {
      font-weight: 600;
      color: $white;
      z-index: 2;
    }
  }
  .subject-section {
    @include for-size(phone) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    @include for-size(tablet) {
      padding-top: 36px;
      padding-bottom: 36px;
    }
    @include for-size(desktop) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  .subject-selector {
    @include for-size(phone) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @include for-size(tablet) {
      padding-top: 50px;
      padding-bottom: 100px;
    }
    .title {
      margin-bottom: 15px;
      font-weight: 600;
      text-align: center;
    }
    .subject-sfaa {
      > .subjectitem {
        @include for-size(phone) {
          margin-bottom: 12px;
        }
        @include for-size(tablet) {
          margin-bottom: 0;
        }
      }
      > .subjectitem > .button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $green;
        font-weight: 600;
        @include for-size(phone) {
          margin-bottom: 6px;
          width: 100%;
          height: 40px;
        }
        @include for-size(tablet) {
          margin-bottom: 12px;
          width: 100%;
          height: 80px;
        }
      }
      > .subjectitem > .button:hover {
        background: $green;
        color: $white;
      }
      > .subjectitem > .button p {
        font-weight: 800;
      }
    }
    .subject-other {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include for-size(phone) {
        padding: 0 15px;
      }
      @include for-size(tablet) {
        padding: 0;
      }
      > .subjectitem {
        display: flex;
        @include for-size(phone) {
          margin-bottom: 12px;
          padding: 0;
          width: 100%;
        }
        @include for-size(tablet) {
          margin-bottom: 0;
          padding: 15px;
          width: calc(100%/3);
        }
        @include for-size(desktop) {
          padding: 15px;
          width: calc(100%/6);
        }
      }
      > .subjectitem > .button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $green;
        font-weight: 600;
        @include for-size(phone) {
          width: 100%;
          height: 50px;
        }
        @include for-size(tablet) {
          width: 100%;
          height: 110px;
        }
      }
      > .subjectitem > .button:hover {
        background: $green;
        color: $white;
      }
      > .subjectitem > .button p {
        font-weight: 800;
      }
      > .subjectitem > .button > .bubble {
        visibility: hidden;
        position: absolute;
        right: calc(100% + 18px);
        top: 50%;
        width: 340px;
        padding: 15px 20px;
        background-color: #747474;
        color: $white;
        transform: translateY(-50%);
        text-align: left;
      }
      > .subjectitem > .button > .bubble:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 100%;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid #747474;
        transform: translateY(-50%);
      }
      > .subjectitem > .button:hover > .bubble {
        visibility: visible;
      }
      > .subjectitem.-sm {
        @include for-size(phone) {
          flex-wrap: nowrap;
          justify-content: space-between;
          align-content: space-between;
        }
        @include for-size(tablet) {
          flex-wrap: wrap;
        }
      }
      > .subjectitem.-sm > .button {
        background: $green;
        color: $white;
        @include for-size(phone) {
          width: 48%;
          height: 50px;
        }
        @include for-size(tablet) {
          width: 100%;
          height: 52px;
        }
      }
      > .subjectitem.-sm > .button:hover {
        border: 2px solid $green;
        background: transparent;
        color: $black;
      }
    }
  }
  .subject-switch {
    overflow: hidden;
    @include for-size(tablet) {
      // background-image: linear-gradient(transparent 98%, #d8d8d8 99%, transparent 100%);
      // background-size: 100% 54px;
    }
    .select {
      @include for-size(phone) {
        display: block;
      }
      @include for-size(tablet) {
        display: none;
      }
    }
    .list {
      @include for-size(phone) {
        display: none;
      }
      @include for-size(tablet) {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  .switch-list {
    display: flex;
    flex-wrap: wrap;
    > .switchitem {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: -100vw;
        bottom: 0;
        right: -100vw;
        height: 1px;
        background: #d8d8d8;
      }
      @include for-size(phone) {
        width: calc(100% / 2);
      }
      @include for-size(tablet) {
        width: calc(100% / 3);
      }
      @include for-size(tablet-more) {
        width: calc(100% / 4);
      }
      @include for-size(desktop) {
        width: calc(100% / 5);
      }
      &.-en {
        display: flex;
        justify-content: center;
        button {
          height: auto;
        }
        @include for-size(phone) {
          width: calc(100% / 1);
        }
        @include for-size(tablet) {
          width: calc(100% / 2);
        }
        @include for-size(desktop) {
          width: calc(100% / 3);
        }
      }
    }
    > .switchitem > button {
      display: block;
      padding: 16px 0;
      width: 100%;
      min-height: 54px;
      text-align: left;
      font-weight: 600;
    }
    @include for-size(phone) {
      > .switchitem > button.-active,
      > .switchitem > button:hover {
        background: $black;
        color: $white;
      }
    }
    @include for-size(tablet) {
      > .switchitem > button.-active {
        background: transparent;
        color: inherit;
        box-shadow: inset 0px -3px 0px 0px $green;
      }
      > .switchitem > button:hover {
        background: transparent;
        color: $green;
      }
    }
  }
  .switch-select {
    display: block;
    position: relative;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 1px solid $black;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease;
    &:before {
      content: "";
      position: absolute;
      right: 20px;
      top: 20px;
      border-top: 5px solid $black;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    > select {
      appearance: none;
      display: block;
      border: 0;
      border-radius: 2px;
      padding: 8px 12px;
      width: 100%;
      height: 48px;
      font-size: 16px;
    }
  }
  .chart-title {
    @include for-size(phone) {
      display: none;
    }
    @include for-size(tablet) {
      display: flex;
      margin-bottom: 40px;
    }
  }
  .chart-content {
    font-weight: 500;
    margin-bottom: 50px;
    border-bottom: 1px solid $black;
    &:last-child {
      border: 0;
    }
    > .number {
      color: $green;
      @include for-size(phone) {
        display: none;
      }
      @include for-size(tablet) {
        display: block;
      }
    }
  }
  .chart-article {
    > .title {
      margin-bottom: 20px;
    }
    > .description {
      line-height: 26px;
    }
  }
  .chart-block {
    margin-top: 40px;
    margin-bottom: 50px;
    > .option {
      margin-bottom: 24px;
    }
    .option {
      display: flex;
      justify-content: space-between;
    }
    > .option > .radiobar {
      display: flex;
    }
    > .option > .radiobar > .radio {
      margin-right: 30px;
    }
    > .chart {
      img {
        max-width: 100%;
      }
    }
  }
  .chart-draw {
    position: relative;
    > .remark {
      margin-top: 15px;
      color: $black;
      &.-active {
        display: block;
      }
    }
    > .draw {
      display: none;
      width: 75%;
      margin: auto;
    }
    > .draw.-active {
      display: block;
    }
    > .table {
      width: 100%;
    }
    svg {
      width: 100%;
      height: auto;
    }
    .pdf-page {
      max-width: 100%;
      height: auto;
    }
    .pdf-page > canvas,
    .pdf-page > div {
      width: 100% !important;
      height: auto !important;
    }
  }
  .article-pager {
    text-align: center;
    background: $green;
    @include for-size(phone) {
      padding-top: 40px;
      padding-bottom: 10px;
    }
    @include for-size(tablet) {
      padding-top: 60px;
      padding-bottom: 30px;
    }
    > .container {
      position: relative;
      min-height: 50px;
    }
    > .container > .top > .icon {
      position: absolute;
      bottom: calc(100% + 4px);
      left: 50%;
      font-size: 24px;
      color: $white;
      transform: translateX(-50%);
      transition: all 0.3s ease;
    }
    > .container > .back:hover > .icon,
    > .container > .top:hover > .icon {
      color: $black;
    }
    > .container > .back > span,
    > .container > .top > span {
      font-weight: 600;
    }
  }
}
