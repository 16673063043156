@import "../../../styles/variables";
.resource-links {
    margin-top: 20px;
    @include for-size(phone) {
        overflow: hidden;
    }
    @include for-size(tablet) {
        overflow: visible;
    }
    > .container {
        @include for-size(phone) {
            padding-left: 0;
            padding-right: 0;
        }
        @include for-size(tablet) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .slick-slider {
        position: relative;
        @include for-size(tablet) {
            padding-left: 60px;
            padding-right: 60px;
        }
        > .slider-prev,
        > .slider-next {
            position: absolute;
            top: 50%;
            // @include for-size(phone) {
            //     display: none;
            // }
            // @include for-size(tablet) {
            //     display: block;
            // }
        }
        > .slider-prev {
            left: 0;
        }
        > .slider-prev:hover {
            transform: translateX(-6px);
        }
        > .slider-next {
            right: 0;
        }
        > .slider-next:hover {
            transform: translateX(6px);
        }
        .slider-item:focus {
            outline: none;
        }
    }
    .link-item {
        display: block;
        padding-left: 12px;
        padding-right: 12px;
        &.-single {
            display: inline-block;
            vertical-align: top;
            width: 33%;
        }
        > .image {
            display: block;
            position: relative;
            width: 100%;
            height: 168px;
            margin-bottom: 24px;
            overflow: hidden;
        }
        > .image img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        > .image:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 20%;
            height: 4px;
            background: $green;
            transition: all .3s ease;
        }
        > .image.-blue:before {
            background: $blue;
        }
        > .image.-red:before {
            background: $red;
        }
        > .image.-pink:before {
            background: $pink;
        }
        > .image:hover:before {
            width: 100%;
        }
        > .title {
            margin-bottom: 4px;
        }
        > .content {
            margin-bottom: 16px;
            line-height: 20px;
            font-weight: 300;
        }
    }
}