@import "../../../styles/variables";

.custom-segment {
    position: relative;
    overflow: hidden;
    z-index: 0;
    > .spy {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        background: $black;
        transition: all .3s ease;
        z-index: -1;
    }
}
.segment-list {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    justify-content:center;
    .segmentitem {
        width: 180px;
        box-shadow: inset 0px 0px 0px 1px $black;
        text-align: center;
        transition: all .3s ease;
    }
    .segmentitem > button {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .segmentitem.-active {
        color: $white; 
    }
    .segmentitem.-active > button {
        color: inherit;
    }
}