@import "../../../styles/variables";

.logo-svg.-light {
    > .top, 
    > .bottom,
    > .left {
        fill: $white;
        opacity: 1;
    }
}
.logo-svg.-dark {
    > .top {
        fill: #B3B3B3;
    }
    > .bottom {
        fill: #666666;
    }
    > .left {
        fill: #747474;
        opacity: 1;
    }
}

.logo-svg.-white {
    > .left {
        fill: $white;
        opacity: 1;
    }
}
.logo-svg.-green {
    > .left {
        fill: $green;
        opacity: 1;
    }
}
.logo-svg.-blue {
    > .top {
        fill: $blue;
        opacity: 1;
    }
}
.logo-svg.-yellow {
    > .left {
        fill: $yellow;
        opacity: 1;
    }
}
.logo-svg.-red {
    > .bottom {
        fill: $red;
        opacity: 1;
    }
}
.logo-svg.-pink {
    > .bottom {
        fill: $pink;
        opacity: 1;
    }
}