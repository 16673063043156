@import "../../../styles/variables";

.custom-select {
    position: relative;
	> label {
		display: block;
		margin-bottom: 5px;
    }
    > .wrap > select {
        appearance: none;
        display: block;
        border: 0;
        border-radius: 2px;
        padding: 8px 12px;
        padding-right: 24px;
        width: 100%;
        height: 48px;
        background: $white;
        font-weight: inherit;
        font-size: 15px;
        &:focus {
            outline: none;
        }
    }
    > .wrap > select > option {
        font-size: 12px;
    }
    > .wrap {
        position: relative;
    }
    .wrap > .icon {
        position: absolute;
        top: 6px;
        right: 0;
        z-index: 2;
        font-size: 36px;
        pointer-events: none;
    }
    > .wrap.-danger {
        border: 1px solid $danger;
        box-sizing: border-box;
    }


    >.msg {
        &.-danger {
            color: $danger;
        }
    }
}