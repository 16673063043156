@import "../../../styles/variables";

.evacuation-page {
	.evacuation-chart-title {
		margin-bottom: 20px;
		border-bottom: 4px solid $pink;
		padding: 15px 0;
		text-align: center;
		.title {
			margin-bottom: 8px;
			font-weight: 600;
			font-size: 28px;
		}
		.subtitle {
			font-size: 18px;
		}
	}
	.evacuation-subject-selector {
		.description {
			margin-bottom: 80px;
			font-weight: 800;
		}
		.buttonbar {
			display: flex;
			justify-content: space-between; 
			.item {
				width: 31%;
				.button {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 20px;
					box-shadow: inset 0px 0px 0px 2px $pink;
					width: 100%;
					height: 80px;
					font-weight: 800;
				}
			}
		}
	}
}
