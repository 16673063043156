@import "../../../styles/_variables";

.page-list {
  display: flex;
  justify-content: center;

  > .pageitem {
    border: 1px solid #000;
    margin-left: 3px;
    margin-right: 3px;
    min-width: 24px;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }

  > .pageitem.-word {
    padding: 0 8px;
  }

  > .pageitem.-active {
    border-color: #000;
    background: #000;
    color: #fff;
    pointer-events: none;
  }
  > .pageitem.-active .sr-only {
    display: none;
  }

  > .pageitem.-disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  > .pageitem:not(.-active):hover {
    &.-green {
      border-color: $green;
      background: $green;
      color: #fff;
    }
    &.-yellow {
      border-color: $yellow;
      background: $yellow;
      color: #fff;
    }
    &.-blue {
      border-color: $blue;
      background: $blue;
      color: #fff;
    }
    &.-red {
      border-color: $red;
      background: $red;
      color: #fff;
    }
    &.-pink {
      border-color: $pink;
      background: $pink;
      color: #fff;
    }
  }

  > .pageitem > a,
  > .pageitem > span {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 22px;
  }
}
