@import "../../../styles/variables";

.news-block {
    > .filter {
        margin-bottom: 40px;
    }
    > .news {

    }
}
.filter-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    > .filterbutton {
        margin: 4px;
        transition: all .3s ease;
        @include for-size(phone) {
            min-width: 40%;
        }
        @include for-size(tablet) {
            min-width: 130px;
        }
    }
    > .filterbutton.-active {
        background: $yellow;
        color: $white;
    }
}
.news-list {
    display: flex;
    justify-content: center;
    > .news-item {
        display: flex;
        padding-left: 15px;
        padding-right: 15px;
    }
    > .news-item > .image {
        display: none;
    }
    > .news-item > .datemonth {
        padding-left: 8px;
        padding-right: 6px;
        margin-right: 24px;
        border-top: 4px solid $yellow;
    }
    > .news-item > .datemonth .month {
        display: block;
        margin-top: 8px;
        margin-bottom: 4px;
        text-align: center;
        letter-spacing: 1px;
    }
    > .news-item > .datemonth .date {
        display: block;
        text-align: center;
        letter-spacing: 1px;
        font-weight: 800;
    }
    > .news-item > .article {
        margin-bottom: 40px;
    }
    > .news-item > .article > .sort {
        margin-bottom: 12px;
        color: #505050;
    }
    > .news-item > .article > .title {
        margin-bottom: 4px;
        font-weight: 600;
    }
    > .news-item > .article > .summary {
        margin-bottom: 24px;
        height: 96px;
        line-height: 24px;
        font-weight: 400;
        color: #505050;
    }
    > .news-item > .article > .read {
        font-weight: 400;
    }
}