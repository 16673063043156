@import "../../../styles/variables";

.evacuation-page {
	padding-top: 95px;
	min-height: 90vh;
}
.evacuation-section {
	@include for-size(phone) {
		padding: 54px 0;
	}
	@include for-size(tablet) {
		padding: 80px 0;
	}
}
.evacuation-heading {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 1;
  .block {
    display: block;
    position: relative;
    font-size: 28px;
    color: $white;
		font-weight: 800;
		background: $black;
		@include for-size(phone) {
			width: 100%;
			height: 200px;
		}
		@include for-size(tablet) {
			width: 33.33%;
			height: 640px;
		}
		&:hover {
			.background {
				opacity: .5;
			}
		}
    .heading {
      position: absolute;
      top: 50%;
      left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			white-space: nowrap;

    }
    .background {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 0.75;
		transition: all .3s ease;
    }
  }
}
.evacuation-info {
	&.-gray {
		background: #F5F5F5;
	}
	> .container {
		margin: 0 auto;
		width: 780px;
		max-width: 100%;
	}
  .sectiontitle {
    border-bottom: 4px solid $pink;
    padding: 15px 0;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 2px;
		text-align: center;
		@include for-size(phone) {
	    font-size: 22px;
		}
		@include for-size(tablet) {
	    font-size: 28px;
		}
  }
  .sectioncontent {
    margin-top: 28px;
    .strong {
      margin: 24px 0;
			font-weight: 800;
			@include for-size(phone) {
				font-size: 18px;
			}
			@include for-size(tablet) {
      	font-size: 20px;
			}
		}
		.table {
			margin: 20px 0;
			overflow: auto;
		}
		.toggle {
			@include for-size(phone) {
				margin: 10px 0;
			}
			@include for-size(tablet) {
				margin: 20px 0;
			}
		}
    p {
      font-size: 18px;
			line-height: 1.75em;
			@include for-size(phone) {
				font-size: 16px;
			}
			@include for-size(tablet) {
      	font-size: 18px;
			}
    }
    > img {
      display: block;
			 
			margin: 40px auto;
      max-width: 100%;
      height: auto;
    }
  }
}
.evacuation-toggle {
	border-radius: 4px;
	color: $white;
	&.-white {
		background: $white;
		color: $blue;
		.controller {
			background: $white;
			.text {
				color: $black;
			}
		}
	}
  .controller {
		display: flex;
		justify-content: space-between;
		border-radius: 4px;
		background: $blue;
		padding: 18px 20px;
		width: 100%;
		height: 60px;
		text-align: left;
		color: inherit;
		.open {
			display: block;
		}
		.close {
			display: none;
		}
		&.-open {
			.open {
				display: none;
			}
			.close {
				display: block;
			}
		}
		.text {
			font-weight: 600;
			@include for-size(phone) {
				font-size: 17px;
			}
			@include for-size(tablet) {
				font-size: 20px;
			}
		}
		.icon {
			span {
				font-size: 16px;
			}
			i {
				margin-left: 12px;
				font-size: 20px;
			}
		}
  }
  .content {
		border-radius: 0 0 4px 4px;
		padding-top: 0;
		max-height: 0;
		font-size: 18px;
		line-height: 1.5;
		transition: all .3s ease;
		overflow: hidden;
		background: $white;
		color: $black;
		> p {
			padding: 18px 20px;
			.sm {
				font-size: 12px;
			}
		}
		&.-show {
			max-height: 3000px;
		}
		.table {
			overflow: auto;
		}
  }
}
.evacuation-download {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #4B4B4B;
	padding: 8px 20px;
	width: 100%;
	.text {
		font-weight: 500;
		text-align: left;
		line-height: 1.5em;
		@include for-size(phone) {
			font-size: 18px;
		}
		@include for-size(tablet) {
			font-size: 20px;
		}
	}
	.icon {
		display: block;
		border: 1px solid $blue;
		border-radius: 4px;
		padding: 6px 10px;
		background: $white;
		color: $black;
		font-size: 16px;
		white-space: nowrap;
		i {
			margin-left: 12px;
		}
	}
}
.evacuation-list {
  font-size: 18px;
	line-height: 1.75;
	  li {
    position: relative;
    margin: 40px 0;
		counter-increment: counter;
		@include for-size(phone) {
			padding-left: 0;
		}
		@include for-size(tablet) {
			padding-left: 26px;
		}
		a {
			color: $pink;
		}
    &:before {
			content: counter(counter);
			display: flex;
			justify-content: center;
			align-items: center;
			width: 19px;
			height: 19px;
			font-size: 15px;
			@include for-size(phone) {
				display: inline-flex;
				margin-right: 4px;
			}
			@include for-size(tablet) {
			position: absolute;
			left: 0;
			top: 0;
			}
    }
	}
	&.-circle {
		li:before {
			margin-top: 6px;
			border: 1px solid $black;
			border-radius: 50%;
		}
	}
	&.-point {
		li:before {
			content: counter(counter)'.';
			margin-top: 6px;
		}
	}
  .label {
    margin-bottom: 8px;
		@include for-size(phone) {
			display: inline-block;
		}
  }
}
.scroll-spy {
	position: fixed;
	top: 50%;
	right: 20px;
	z-index: 1;
	text-align: right;
	transform: translateY(-50%);
	@include for-size(phone) {
		display: none;
	}
	@include for-size(tablet) {
		display: block;
	}
	.item {
		clear: both;
		float: right;
		display: block;
		margin: 4px 0;
		cursor: pointer;
		&.-show, &:hover {
			&:after {
				background: $black;
			}
			span {
				display: inline-block;
			}
		}
		&:after {
			content: "";
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
			border: 1px solid $black;
			border-radius: 50%;
			width: 6px;
			height: 6px;
		}
		span {
			display: none;
			vertical-align: middle;
			font-size: 15px;
		}
	}
}
.evacuation-articlelist {
	> .container {
		margin: 0 auto;
		width: 780px;
		max-width: 100%;
	}
  .sectiontitle {
		margin-bottom: 20px;
    border-bottom: 4px solid $pink;
    padding: 15px 0;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 2px;
    text-align: center;
  }
}