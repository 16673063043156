@import "../../../styles/variables";

.side-menu {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $yellow;
    transition: all .3s ease;
    z-index: 150;
    &.-open {
        visibility: visible;
        transform: translateX(0%);
    }
    &.-close {
        visibility: hidden;
        transform: translateX(100%);
    }
    > .close {
        position: absolute;
        background: #FFF;
        @include for-size(phone ) {
            top: 15px;
            right: 15px;
        }
        @include for-size(tablet) {
            top: 24px;
            right: 40px;
        }
    }
    > .container {}
    .list-wrapper {
        display: flex;
        align-items: center;
        padding: 0px 50px;
        height: 100%;
        > .list {
        }
    }
    
    .primary-list {
        width: 100%;
        > .item {
            position: relative;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 10px;
            cursor: pointer;
        }
        > .item:before {
            content: "";
            position: absolute;
            top: 22px;
            left: 0;
            width: 0px;
            height: 4px;
            background: $white;
            transition: all .3s ease;
        }
        > .item > .link {
            display: block;
            font-size: 20px;
            font-weight: 600;
            transition: all .3s ease;
        }
        > .item:hover:before {
            width: 20px;
        }
        > .item:hover > .link {
            transform: translateX(32px);
        }
        @include for-size(phone) {
            > .item:hover > .secondary-list {
                display: block;
            }
        }
        @include for-size(tablet) {
            > .item:hover > .secondary-list {
                visibility: visible;
                opacity: 1;
                transform: translateX(10px);
            }
        }
    }
    .secondary-list {
        margin-top: 12px;
        min-width: 450px;
        transition: all .3s ease;
        transform: translateX(0);
        @include for-size(phone) {
            display: none;
            padding-left: 12px;
        }
        @include for-size(tablet) {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 100%;
            padding-left: 60px;
        }
        > .item {
            position: relative;
            margin-bottom: 15px;
            font-size: 16px;
        }
        > .item:hover {
            color: $white;
            @include for-size(phone) {
              color: inherit;
            }
        }
        > .item:before {
            content: "";
            position: absolute;
            top: 12px;
            left: -14px;
            width: 4px;
            height: 4px;
            background: #FFF;
        }
        > .item > .button {
            
        }
    }
}

