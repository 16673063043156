@import "../../../styles/_variables";


.news-item {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;

  > .datemonth {
    padding-left: 8px;
    padding-right: 6px;
    margin-right: 24px;
    border-top: 4px solid $yellow;
  }
  > .datemonth .month {
    display: block;
    margin-top: 8px;
    margin-bottom: 4px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  > .datemonth .date {
    display: block;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 800;
  }
  > .image {
    margin-right: 30px;
    width: 190px;
    height: 140px;
  }
  > .image img {
    display: block;
    width: 100%;
    height: auto;
  }
  > .article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 100%;
    // width: calc(100% - 300px);
  }
  // > .article > .sort {
  //   margin-bottom: 12px;
  //   color: #505050;
  // }
  > .article > .title {
    margin-bottom: 4px;
    font-weight: 600;
  }
  > .article > .summary {
    margin-bottom: 24px;
    line-height: 25px;
    font-weight: 400;
    color: #505050;
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  > .article > .read {
    display: block;
    text-align: right;
    a {
      display: inline-block;
      border: $yellow 1px solid;
      padding: 5px 24px;
      padding-right: 12px;
      background-color: $yellow;
      font-weight: 400;
      transition: background-color .3s;
      box-sizing: border-box;
      &:hover {
        background-color: $white;
      }
    }
  }
  > .article > .read > i {
    animation: arrow-animation 1s infinite ease;
  }
}