@import "../../../styles/variables";

.homepage-section {
	padding-top: 60px; 
	padding-bottom: 60px; 
	.sectiontitle {
		display: block;
		margin-bottom: 40px;
		text-align: center;
	}
}
.big-banner {
	position: relative;
	height: 100vh;
	background-position: center center;
	background-size: cover;
	> .leftbtn,
	> .rightbtn {
		position: absolute;
		color: $white;
		z-index: 2;
		@include for-size(phone) {
			bottom: 12px;
		}
		@include for-size(tablet) {
			top: 48%;
			bottom: auto;
		}
        > .icon {
            border: 1px solid;
            border-color: transparent;
            border-radius: 50%;
            margin: 0 12px;
            background: $yellow;
            color: $black;
			text-align: center;
			@include for-size(phone) {
            	padding: 8px;
				font-size: 15px;
			}
			@include for-size(tablet) {
            	padding: 12px;
				font-size: 24px;
			}
        }
	}
	> .leftbtn {
		@include for-size(phone) {
			left: 8px;
		}
		@include for-size(tablet) {
			left: 40px;
		}
	}
	> .rightbtn {
		@include for-size(phone) {
			right: 8px;
		}
		@include for-size(tablet) {
			right: 40px;
		}
	}
	> .scrolldown {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 1px;
		height: 60px;
		background: $white;
	}
	> .scrolldown:before {
		content: "";
		position: absolute;
		top: 0;
		left: -1px;
		width: 3px;
		height: 20px;
		background: $white;
		animation: ScrollDownAnimation 1.5s linear infinite;
	}
}
.big-logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 540px;
	max-width: 80%;
	height: 540px;
	svg {
		fill: $white;
		width: 100%;
		height: 100%;
	}
	svg > .top,
	svg > .bottom,
	svg > .left,
	svg > .don {
		fill: $yellow;
	}
	svg > .top { animation: LogoAnimation 6s linear infinite }
	svg > .left { animation: LogoAnimation 6s linear infinite 2s }
	svg > .bottom { animation: LogoAnimation 6s linear infinite 4s }
}
.contact-block {
	background: $yellow;
}
.series-block{
	background: #f5f5f5;
}
.subscribe-block {
	background: $black;
	.type-heading, .type-text {
		color: $white;
	}
}