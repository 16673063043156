@import "../../../styles/variables";

.survey-subtitle {
    > .en {
        font-weight: 400;
    }
}
.survey-links {
    max-width: 450px;;
    min-height: 420px;
    > .link {
        display: block;
        position: relative;
        margin: 12px 0;
        padding-bottom: 24px;
        line-height: 20px;
        color: $black;
        transition: all .3s ease;
    }
    > .link:hover {
        color: $green;
    }
    > .link:before {
        content: "";
        position: absolute;
        left: 0;
        top: -12px;
        width: 40px;
        height: 2px;
        background: $green;
    }
    > .link > .-en {
        font-weight: 400;
    }
}
.survey-buttonbar {
    display: flex;
    justify-content: space-between;
    max-width: 450px;;
    .direction-button {
        > .text {
            font-weight: 800;
        }
        > .icon {
            border: 1px solid;
            border-color: $blue;
            border-radius: 50%;
            margin: 0 12px;
            padding: 12px;
            width: 48px;
            height: 48px;
            color: $blue;
            font-size: 24px;
            text-align: center;
        }
    }
    .direction-button.-prev {
        > .icon {
            border-color: $yellow;
            color: $yellow;
        }
    }
}