@import "../../../styles/variables";

.news-article {
  padding-top: 135px;
  margin-bottom: 100px;
  
  @include for-size(phone) {
    min-height: calc(100vh - 349px);
  }

  @include for-size(tablet) {
    min-height: calc(100vh - 481px);
  }

  .article-heading {
    
    .datemonth {
      border-top: 4px solid currentColor;

      &.-green {
        color: $green;
      }
  
      &.-yellow {
        color: $yellow;
      }
    }

    animation: fade-in 0.3s;
    

    .datemonth > .month {
        display: block;
        padding-top: 10px;
        font-weight: 600;
    }
    .datemonth > .date {
        display: block;
        padding-top: 4px;
        font-weight: 800;
    }
    .minititle {
        margin-bottom: 10px;
        font-size: 14px;
    }
    .heading {
        margin-bottom: 50px;
        line-height: 40px;
        font-weight: 800;
    }
  }

  .article-content {
      animation: fade-in 0.3s;

      .image {
        margin-bottom: 40px;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .author > label, .sorting > label {
          font-weight: 800;
          margin-right: 14px;
      }
      .author {
          margin-bottom: 8px;
      }
      .sorting {
          margin-bottom: 50px;
      }
      .content {
        img {
            max-width: 100%;
            height: auto;
        }
      }
  }

  .-loading-block {
    box-shadow: 0 1.5em rgba($gray , 0.3) inset;
    color: transparent;

    &.empty-content {
        margin-bottom: 20px;
    }
  }

    @keyframes fade-in {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
}