@import "../../../styles/variables";

.new-morakot-page {
  .morakot-advancetitle {
    margin-bottom: 20px;
    border-bottom: 4px solid $green;
    padding: 15px 0;
    letter-spacing: 2px;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }
  .morakot-advance {
    .head {
      margin-bottom: 24px;
      letter-spacing: 0.5px;
      .title {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .advance-side {
      .icon {
        margin: 12px 0;
        text-align: center;
      }
      .step {
        .button {
          display: block;
          margin-bottom: 10px;
          border: 1px solid $green;
          border-radius: 4px;
          padding: 12px;
          width: 100%;
          font-weight: 600;
          &:hover, &.-active {
            color: $white;
            background: $green;
          }
        }
        .title {
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: 0.5px;
        }
        .description {
          font-size: 15px;
          line-height: 1.6;
          // font-weight: 300;
          letter-spacing: 0.5px;
        }
      }
    }
    .advance-enter {
      margin-top: 80px;
      margin-bottom: 100px;
      p {
        margin-bottom: 16px;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 1.1px;
      }
      button, a {
        display: block;
        margin-bottom: 15px;
        border-radius: 4px;
        padding: 16px;
        width: 100%;
        // background: #505050;
        // color: $white;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
      }
      .description {
        font-size: 16px;
        line-height: 1.5;
      }
    }
    .advance-info {
      .image video {
        width: 100%;
        height: auto;
      }
    }
  }
}
