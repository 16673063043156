@import "../../../styles/variables";

.morakot-area-page {
  .morakot-area-subject {
    margin: auto;
    width: 900px;
    max-width: 100%;
    .description {
      margin-bottom: 40px;
      font-size: 18px;
    }
    .subject-buttonbar {
      margin-bottom: 40px;
      .button {
        margin-right: 20px;
        margin-bottom: 20px;
        border: 2px solid $green;
        border-radius: 20px;
        width: 180px;
        height: 40px;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
      }
    }
    .subject-section {
      .subject-title {
        margin-bottom: 20px;
        padding-right: 12px;
        font-size: 24px;
        font-weight: 800;
      }
      .subject-description {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
    .subject-resource {
      margin-bottom: 40px;
    }
    .subject-download {
      margin-bottom: 80px;
    }
  }
}