@import "../../../styles/variables";

.login-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.55);
  z-index: 2;
  .msg {
    width: 340px;
    height: 180px;
    background: $white;
    padding: 48px 60px;
    text-align: center;
    p {
      margin-bottom: 18px;
    }
  }
}
