@import "../../../styles/variables";

.evacuation-chart-draw {
  padding-top: 12px;
  > .controller {
    display: flex;
    .radio {
      margin-right: 24px;
    }
  }
  > .draw {
    background: #F9F9F9;
  }
}