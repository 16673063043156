button {
    background: transparent;
    border: 0;
    cursor: pointer;
}
button:focus {
  outline: none;
}
a {
    color: inherit;
    text-decoration: none;
}
* {
  box-sizing: border-box;
}
//---------------------------
//
// Reset: Using Eric Meyer’s “Reset CSS” 2.0
//
//---------------------------

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  // font: inherit;
  font-size: 100%;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
	border-collapse: collapse;
}
button, input {
  margin: 0;
  padding: 0;
  letter-spacing: inherit;
  line-height: inherit;
  font-size: inherit;
  font-family: inherit;
}