@import "../../../styles/variables";

//共用
.HualienEarthquake {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        font-family: inherit;
        font-weight: 800;
        line-height: 1.3;
        letter-spacing: 1.2px;
        color: inherit;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 1.6px;
        line-height: 1.9;
        // text-align:justify;

        a {
            text-decoration: underline;
            font-weight: 800;
            color: #ffad00;
        }
    }

    a {
        text-decoration: none;
        color: unset;
        background-color: transparent;

    }

    .text-center {
        text-align: center;
    }

    section {
        padding: 30px 0px;
        margin: 0 30px;
        @media (max-width: 640px) {
            padding: 20px 0px;
        }
      
    }

    .container-c {

        margin: 0 auto;
        position: relative;

        &.c640 {
            width: 640px;

            @media (max-width: 640px) {
                width: 100%;
            }
        }

        &.c886 {
            width: 886px;

            @media (max-width: 886px) {
                width: 100%;
            }
        }

        &.c1200 {
            width: 1200px;

            @media (max-width: 1200px) {
                width: 100%;
            }
        }

        .infon {
            position: relative;
            z-index: 100;

            .title {
                text-align: center;

                h5 {
                    font-size: 24pt;
                    font-weight: 300;
                    padding-bottom: 0px;
                    margin-bottom: 0px;

                    @media (max-width: 640px) {
                        font-size: 14pt;
                    }
                }

                h4 {
                    position: relative;
                    font-size: 20pt;
                    margin: 0px 0 40px 0;
                    padding: 30px 20px;
                    border: 8px #fec200 solid;
                    border-radius: 15px;

                    @media (max-width: 640px) {
                        font-size: 14pt;
                        margin: 10px 0;

                        border: 4px #fec200 solid;
                    }
                    &::before{
                        content: "";
                        position: absolute;
                        top: -25px;
                        left: 10%;

                        height: 100px;
                        z-index: 999;
                        width: 100%;

                        background-image: url("../../../images/HualienEarthquake/colon2.svg");
                        background-size: 180%;
                        background-repeat: no-repeat;
                        @media (max-width: 640px) {
                            top: -13px;
                        }
                       
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        bottom: -25px;
                        right: 10%;

                        height: 100px;
                        z-index: 999;
                        width: 100%;

                        background-image: url("../../../images/HualienEarthquake/colon2.svg");
                        background-size: 180%;
                        background-repeat: no-repeat;

                        transform: rotate(180deg);
                        @media (max-width: 640px) {
                            bottom: -15px;
                        }
                    }

                }

                h3 {
                    font-size: 24pt;
                    margin-top: 0;
                    margin-bottom: 1em;
                    padding-top: 0;

                    @media (max-width: 640px) {
                        font-size: 24pt;
                    }

                    @media (max-width: 375px) {
                        font-size: 20pt;

                    }

                }
                h2 {
                    font-size: 31pt;
                    margin-top: 0;
                    margin-bottom: 1em;
                    padding-top: 0;
                    position: relative;

                    @media (max-width: 640px) {
                        font-size: 24pt;
                        
                    }

                    @media (max-width: 375px) {
                        font-size: 20pt;

                    }
                    &::after {
                        background-color: rgba(255, 217, 111, 0.4);
                        border-radius: 20px;
                        width: 500px;
                        height: 50px;
                        content: "";
                        position: absolute;
                        top: 18px;
                        z-index: -1;
                        left: 50%;
                        margin-left: -250px;
    
                        @media (max-width: 640px) {
                            width: 100%;
                            left: 0;
                            margin-left: 0;
    
                            top: 10px;
                        }
                    }

                }

                
            }

            p {
                margin: 1em 0;
                text-align:justify;
            }
            h2 {
                font-size: 24pt;
                font-weight: 800;
            }
            
        }
        .pic{
            margin: 3em 0;  
            @media (max-width: 640px) {
                margin: 1em 0;  
            }
        }
       
        .btn-g {
            width: 100%;

            .btn {
                font-size: 18pt;
                font-weight: 800;
                line-height: 28px;
                color: #ffffff;
                // width: calc(100% - 50px);
                width: 100%;
                background: #000000;
                border-radius: 10px;
                padding: 25px;
                margin-bottom: 30px;
                position: relative;
                display: block;

                @media (max-width: 640px) {
                    line-height: 22px;

                    padding: 15px 40px 15px 15px;
                    font-size: 14pt;

                }

                @media (max-width: 375px) {
                    font-size: 10pt;
                    margin-bottom: 10px;
                }

                &::after {
                    width: 39px;
                    height: 39px;
                    position: absolute;
                    right: 25px;
                    top: 50%;
                    margin-top: -19px;
                    // content: url(../images/icon01.svg);
                    content: "";
                    background-image: url("../../../images/HualienEarthquake/icon01.svg");
                    background-size: 90%;
                    background-position: center;
                    background-repeat: no-repeat;

                    @media (max-width: 640px) {
                        right: 3px;
                        background-size: 65%;
                    }

                }

                small {
                    font-size: 16px;
                    font-weight: 200;

                    @media (max-width: 640px) {
                        font-size: 9pt;
                    }
                }

            }
        }

        blockquote {

            width: 100%;
            background: #EFEFEF;
            border-radius: 20px;
            margin: 30px auto 0;
            padding: 30px 0;

            margin-bottom: 60px;

            display: flex;
            flex-direction: column;
            align-items: center;
            .title{
                font-size: 20px;
                padding-bottom: 20px;
                width: 640px;

                @media (max-width: 640px) {
                    width: 75%;
                }    
            }
            p {
                font-size: 16px;
                font-weight: 200;
                width: 640px;

                @media (max-width: 640px) {
                    width: 75%;
                }
            }
        }
    }

    //.container-c

    //chart-block
    .chart-block {
        margin-top: 40px;
        margin-bottom: 50px;

        .option {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .radiobar {
                display: flex;

                .radio {
                    margin-right: 30px;
                }
            }
        }
    }

    .statistics-charts {
        display: none;
        margin: 30px auto;
        @media (max-width: 375px) {
            margin: 10px 0;
        }

        &.-active {
            display: block;
        }
    }
    .statistics-datatable {
        display: none;
        width: 100%;
        overflow: auto;
        margin: 60px auto;

        .-active {
            display: block;
        }
    }

    //隱藏scroll-up
    .header
    {
        z-index: 80;
        position: relative;
        background-color: white;
    }


    .scroll-up {
        position: fixed;
        z-index: 200;
        bottom: .5em;
        right: 1em;
        display: block;

        a {

            display: block;
            text-align: center;
            color: #fff;
            font-size: 15px;
            line-height: 30px;

            width: 90px;
            height: 90px;
            margin: 0px;
           
            img{
                width: 90px;
            }
            &.pc{
                display: block;
                @media (max-width: 768px) {
                    display: none;
                } 
            }
            &.mobile{
                display: block;
                @media (min-width: 767px) {
                    display: none;
                }
            }

        }
        @media (max-width: 640px) {
            a {
                width: 50px;
                height: 50px;
                img{
                    width: 50px;
                } 
            }
        }
    }

    // scrollbar
    /* width */
    ::-webkit-scrollbar {
        // width: 50px;
        height: 20px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
    
        border-radius: 10px;
        background-color: rgb(234, 234, 234);
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $yellow; 
        border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba($yellow, 0.5)
    }
    //標題茅點
    .scroll-spy {
        position: fixed;
        top: 50%;
        right: 20px;
        z-index: 300;
        text-align: right;
        transform: translateY(-50%);
        @include for-size(phone) {
            display: none;
        }
        @include for-size(tablet) {
            display: block;
        }
        .item {
            clear: both;
            float: right;
            display: block;
            margin: 4px 0;
            cursor: pointer;
            &.-show, &:hover {
                &:after {
                    background: $black;
                }
                span {
                    display: inline-block;
                }
            }
            &:after {
                content: "";
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                border: 1px solid $black;
                border-radius: 50%;
                width: 6px;
                height: 6px;
            }
            span {
                display: none;
                vertical-align: middle;
                font-size: 15px;
                padding: 0px 5px;
                background-color: #d8d8d8;
            }
        }
    }
}

//
.HualienEarthquake {
    padding-top: 95px;
    min-height: 90vh;

    .header {
        display: block;
        position: relative;
        height: 100vh;
        overflow: hidden;

        .seismicwave{
            width: 100%;
            height: 100%;
            position: absolute;
            top: -10%;
            left: 0px;

            z-index: 130;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
        .maintitle {
            width: 100%;
            height: 100%;
            position: absolute;
            top: -10%;
            left: 0px;
            color: transparent;

            z-index: 50;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            img {
                width: 688px;

                @media (max-width: 640px) {
                    width: 80%;
                }
            }

        }

        .mass {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            color: transparent;

            opacity: .3;
            z-index: 5;

            background-color: white;
        }

        .slideshow {
            list-style: none;
            z-index: 1;


            li {

                
                span {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    color: transparent;
                    background-size: cover;
                    background-position: 50% 50%;
                    background-repeat: none;
                    opacity: 1;
                    transform: scale(1);
                    z-index: 0;
                    // animation: ZoomAnimation 10s linear infinite 0s;

                }

                &:nth-child(1) span {
                    z-index: -1;
                    background-image: url("../../../images/HualienEarthquake/1201.jpg");
                }

                &:nth-child(2) span {
                    background-image: url("../../../images/HualienEarthquake/1202.jpg");
                   
                    -webkit-animation: imageAnimation 10s linear infinite 0s;
                    -moz-animation: imageAnimation 10s linear infinite 0s;
                    animation: imageAnimation 10s linear infinite 0s;

                }
            }
            @-webkit-keyframes ZoomAnimation {
                0%{
                    transform: scale(1);
                }
                100%{
                    transform: scale(1.5);
                }
            }

            @-webkit-keyframes imageAnimation {
                0% {
                  
                    opacity: 1;

                }

                35.5% {
                   
                    opacity: 1;
                    -webkit-animation-timing-function: ease-in;
                }

                45% {
                  
                    opacity: 0;

                }

                85.5% {
                   
                    opacity: 0;
                    -webkit-animation-timing-function: ease-in;
                }

                100% {
                    
                    opacity: 1;
                }
            }

            @-moz-keyframes imageAnimation {
                0% {
                    opacity: 1;

                }

                35.5% {
                    opacity: 1;
                    -webkit-animation-timing-function: ease-in;
                }

                45% {
                    opacity: 0;

                }

                85.5% {
                    opacity: 0;
                    -webkit-animation-timing-function: ease-in;
                }

                100% {

                    opacity: 1;
                }
            }

            @keyframes imageAnimation {
                0% {
                    opacity: 1;

                }

                35.5% {
                    opacity: 1;
                    -webkit-animation-timing-function: ease-in;
                }

                45% {
                    opacity: 0;

                }

                85.5% {
                    opacity: 0;
                    -webkit-animation-timing-function: ease-in;
                }

                100% {

                    opacity: 1;
                }
            }



            .no-cssanimations .slideshow li span {
                opacity: 1;
            }
        }
    }

    hr{
        margin: 80px 0;
        height: 10px;
        background-color: #fec200;
        border: 0;
    }


    #content02 {
        margin-bottom:200px;
        @media (max-width: 640px) {
            margin-bottom: 0px;
        }

        .btn-g{
            margin-top: -800px;
            @media (max-width: 640px) {
                margin-top: -300px;
            }
        }
        @media (max-width: 375px) {
           
        }
    }

    #content04{
        .pic01{
            text-align: center;
            img{
                width: 80%;
            }
        }
    }
    #content06 {
        .container-c {
            position: relative;
            
            .chat03-1 {
                position: absolute;
                left: 0;
                top: 6px;
                width: 60px;
                z-index: 100;
                margin: 0;
                @media (max-width: 640px) {
                    top: 6px;
                    left:-20px;
                    width: 44px;
                }
            }
            .chat03-2 {
                position: relative;
                overflow-x: scroll;
                margin: 50px 0px 60px 90px;
                @media (max-width: 640px) {
                    margin: 50px 0px 20px 30px;
                }
                .scrollbox{
                    margin: 0px 0 50px 0;
                    width: 1850px;
                    @media (max-width: 640px) {
                        width: 1370px;
                    }
                    
                }

             }
             .chat03-3 {
                width: 75%;
                @media (max-width: 640px) {
                    width: 100%;
                }
             }
        }
    }


//推薦閱讀
.svi-articlelist{
    .sectiontitle{
        border-bottom: 4px solid #fec200;
    }
    .subject-list{
        .article-item{
            & > .image.-black:before {
                background: #fec200;
            }
            @media (min-width: 768px) {
                & > .content > .title {
                    white-space: normal;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // margin-bottom: 10px;
                }
            }
        }
    }
    
}

    //資料來源
    .into{
        margin: 0 auto;
        // width: 780px; 
        max-width: 100%;
        background-color: #e7e7e7;
        > .container{
            margin: 0 auto;
            width: 780px;
            max-width: 100%;

            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            @media (max-width: 640px) {
                display: flex;
                align-items: flex-start;
                flex-direction: column; 
            }
            .title{
                h3 {
                    font-size: 28px;
                    line-height: 30px;
                    color: #515151;
                    font-family: Microsoft-JhengHei-Bold;
                    margin-bottom: 30px;
                }
                h6 {
                    font-size: 18px;
                    line-height: 25px;
                    color: #515151;
                    font-weight: 400;
                    margin-bottom: 0px;
                    @media (max-width: 640px) {
                        margin-bottom: 30px;
                    }
                }
            }
            
            ul {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-row-gap: 40px;
                grid-column-gap: 30px;
                li {
                    font-size: 18px;
                    color: #515151;
                    position: relative;
                 
                    a {
                        
                        color: #292929;
                        border-bottom: 1px solid #afafaf;
                    }
                   
                }
            }
        }
        
    }


//修正標題手機版斷行
@media (max-width: 640px) {
     .container-c .infon .title h2 br,
     .container-c .infon .title h3 br,
     .container-c .infon .title h4 br,
     .container-c .infon .title h5 br
     {
        display: none;
    }
}


}

