@import "../../../styles/variables";

.morakot-area-page {
  .map-selector {
    margin-bottom: 120px;
    .submit {
      margin: 0 auto;
      margin-bottom: 24px;
      width: 420px;
    }
    .notes {
      margin-top: 12px;
      font-size: 13px;
      color: #606060;
      line-height: 1.8;
    }
  }
}