@import "../../../styles/variables";
.svi-map {
  width: 100%;
  height: auto;
  &.-default {
    .city {
      stroke: hsla(0, 0%, 0%, .2);
      stroke-width: 1;
      fill: transparent;
    }
  }
  .city {
    transition: all .3s ease;
  }
  .county {
    stroke: hsla(0, 0%, 0%, .2);
    stroke-width: .2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
.svi-mark {
  // margin: auto;
  padding: 10px 20%;
  // width: 400px;
  &.-indicator .note {
    display: none;
  }
  .useless {
    position: relative;
    left: -36px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 15px;
    }
    .color {
      margin-right: 8px;
      width: 16px;
      height: 8px;
    }
  }
  .bar {
    position: relative;
    width: 100%;
    transform: skewX(-30deg);
    .start, .middle, .end {
      height: 8px;
    }
    .start {
      position: absolute;
      top: 0;
      right: 99%;
      width: 30px;
    }
    .end {
      position: absolute;
      top: 0;
      left: 99%;
      width: 30px;
    }
  }
  .note {
    display: flex;
    justify-content: space-between;
    margin: 0 -32px;
    font-size: 13px;
    color: #505050;
  }
  .point {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    font-size: 15px;
    font-family: "Roboto";
  }
}
.svi-capture {
  position: absolute;
  text-align: center;
}
@keyframes delayDisplay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.svi-z-map {
  position: relative;
  opacity: 0;
  animation: delayDisplay .15s ease .6s forwards;
  .bubble-info {
    .bubble {
      position: absolute;
      bottom: 24px;
      left: 0;
      border-radius: 4px;
      padding: 8px 16px;
      min-width: 100px;
      background: #505050;
      transform: translateX(-50%);
      pointer-events: none;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 16px solid #505050;
        transform: translateX(-50%);
      }
      .name {
        font-size: 14px;
        color: $lightGray;
      }
      .value {
        color: $white;
        font-size: 20px;
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }
  .range-mark {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    .title {
      margin-bottom: 15px;
      font-size: 14px;
    }

    .range-noData{
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: 'Roboto Condensed';
      line-height: 14px;
      margin-bottom: 8px;

      .colorBar {
        width: 24px;
        height: 24px;
        background-color: #dddddd;
        margin-right: 8px;
      }
    }

    .range {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .colorBar {
        margin-right: 8px;
        width: 24px;
        height: 240px;
      }
      .valueBar {
        position: relative;
        height: 240px;
        .value {
          font-size: 14px;
          font-family: 'Roboto Condensed';
          line-height: 14px;
          
        }
        .zero {
          position: absolute;
          bottom: 50%;
        }
        .buttom{
          position: absolute;
          bottom: 0;
        }
      }
    }
    .color {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
    
  }
  .taiwan-map {
    position: relative;
    padding: 0 120px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    &.-show {
      opacity: 1;
      visibility: visible;
    }
    .react-svg-zoom-map {
      pointer-events: visibleFill;
      height: 650px;
      .labels, .controls { display: none }
    }
    .svi-png {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        height: 600px;
        width: auto;
      }
    }
  }
}
