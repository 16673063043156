@import "../../../styles/variables";
.controller-list {
    display: flex;
    > .controlleritem {

    }
}
.svg-block {
    margin-bottom: 36px;
   g[id*="layer"] {
       visibility: hidden;
       opacity: 0;
       transition: all .3s ease;
   }
   g[id*="layer"].-active {
       visibility: visible;
       opacity: 1;
   }
   g[id*="btn"] {
       opacity: .5;
       cursor: pointer;
   }
   g[id*="btn"].-active {
       opacity: 1;
   }
}