.msg-box {
    // display: none;
    position: absolute;
    top: 64px;
    right: 96px;
    border: 1px solid #000;
    padding: 20px;
    width: 360px;
    color: #FFF;
    background: #000;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 24px;
    z-index: 2;
    > .controller {

    }
    > .controller > .hide,
    > .controller > .more {
        display: inline-block;
        border: 0;
        padding: 0;
        background: transparent;
        line-height: 1em;
        letter-spacing: 1px;
        font-weight: 600;
    }
    > .controller > .hide {
        border-right: 2px solid #FFF;
        padding-right: 12px;
        color: #FFF;
    }
    > .controller > .more {
        padding-left: 12px;
        color: #FDC100;
    }
    > .controller > .more:hover {
        text-decoration: none;
    }
}