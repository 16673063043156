@import "../../../styles/variables";

.new-sfaa-page {
  .sfaa-chart-title {
    margin-bottom: 20px;
    border-bottom: 4px solid $green;
    padding: 15px 0;
    text-align: center;
    .title {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 28px;
    }
    .subtitle {
      font-size: 18px;
    }
  }
  .sfaa-subject-selector {
    .description {
      margin-bottom: 80px;
      font-weight: 800;
    }
    .buttonbar {
      display: flex;
      justify-content: space-between;
      .item {
        width: 31%;
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          box-shadow: inset 0px 0px 0px 2px $green;
          width: 100%;
          height: 80px;
          font-weight: 800;
        }
      }
    }
  }

  .subject-grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
    > .subjectitem {
			width: 18%;
      display: inline-flex;
      @include for-size(phone) {
        margin-bottom: 12px;
      }
      @include for-size(tablet) {
        margin-bottom: 30px;
      }
    }
    > .subjectitem > .button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 2px solid $green;
      border-radius: 4px;
      @include for-size(phone) {
        width: 100%;
        height: 50px;
      }
      @include for-size(tablet) {
        width: 100%;
        height: 115px;
      }
      @include for-size(desktop) {
        // width: 160px;
        height: 115px;
      }
    }
    > .subjectitem > .button p {
      font-weight: 800;
    }
    > .subjectitem > .button:hover {
      background: $green;
      color: $white;
    }
    > .subjectitem > .button > .bubble {
      visibility: hidden;
      position: absolute;
      right: calc(100% + 18px);
      top: 50%;
      width: 340px;
      padding: 15px 20px;
      background-color: #747474;
      color: $white;
      transform: translateY(-50%);
      text-align: left;
    }
    > .subjectitem > .button > .bubble:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid #747474;
      transform: translateY(-50%);
    }
    > .subjectitem > .button:hover > .bubble {
      visibility: visible;
    }
    > .subjectitem.-sm {
			width: 38%;
      @include for-size(phone) {
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: space-between;
      }
      @include for-size(tablet) {
        flex-wrap: wrap;
      }
    }
    > .subjectitem.-sm > .button {
      background: $green;
      color: $white;
      @include for-size(phone) {
        // width: 48%;
        height: 50px;
      }
      @include for-size(tablet) {
        // width: 100%;
        height: 52px;
      }
      @include for-size(tablet-more) {
        // width: 160px;
        height: 52px;
      }
    }
    > .subjectitem.-sm > .button:hover {
      border: 2px solid $green;
      background: transparent;
      color: $black;
    }
  }
}
