@import "variables";

.article-content .container .content{
  h1 {
    font-size: 28px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    // font-weight: 300;
    margin-bottom: 20px;
  }

  a {
    font-size: 18px;
    line-height: 26px;
    // font-weight: 300;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  &.-yellow {
    a { color: $yellow; }
  }

  &.-green {
    a { color: $green; }
  }
  
  &.-red {
    a { color: $red; }
  }

  &.-pink {
    a { color: $pink; }
  }
  
  &.-blue {
    a { color: $blue; }
  }
  ul { 
   list-style-type: disc; 
   list-style-position: inside; 
  }
  ol { 
    list-style-type: decimal; 
    list-style-position: inside; 
  }
  ul ul, ol ul { 
    list-style-type: circle; 
    list-style-position: inside; 
    margin-left: 15px; 
  }
  ol ol, ul ol { 
    list-style-type: lower-latin; 
    list-style-position: inside; 
    margin-left: 15px; 
  }
  li {
    font-size: 18px;
    line-height: 26px;
    // font-weight: 300;
  }
  img {
      max-width: 100%;
      height: auto;
  }
}