@import "../../../styles/variables";
.side-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 150;
    > .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        background: rgba($black, .5);
        transition: all .3s ease;
        z-index: 1;
    }
    > .mask > .image {
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        background-position: center center;
        background-size: cover;
    }
    > .side {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background: $white;
        transition: all .3s ease;
        z-index: 2;
        overflow: auto;
        @include for-size(phone) {
            width: 100%;
        }
        @include for-size(desktop) {
            width: 50%;
        }
    }
    > .side.-left {
        left: 0;
        transform: translateX(-100%);
    }
    > .side.-left + .mask > .image {
        right: 0;
    }
    > .side.-right {
        right: 0;
        transform: translateX(100%);
    }
    > .side.-right + .mask > .image {
        left: 0;
    }
    > .side > .close {
        position: absolute;
        font-size: 40px;
        @include for-size(phone) {
            top: 15px;
            right: 15px;
        }
        @include for-size(tablet) {
            top: 40px;
            right: 50px;
        }
    }
    > .side > .main {
        margin: auto;
    }
}
.side-content {
    display: flex;
    overflow-y: auto;
    scroll-behavior: smooth;
    @include for-size(phone ) {
        padding: 80px 15px;
    }
    @include for-size(tablet) {
        padding: 80px 60px;
    }
}
// Color Style
.side-content.-gray {
    background: $lightGray;
}
.side-content.-green {
    background: $green;
}
.side-content.-white {
    background: #F1F1F1;
}


.modal-main {
    max-width: 90%;
    > .modalheader {
        margin-bottom: 12px;
    }
    > .modalbody {}
    > .modalfooter {}
}
.side-modal.-close {
    visibility: hidden;
    // display: none;
}
.side-modal.-open {
    visibility: visible;
    // display: block;
    > .mask {
        opacity: 1;
    }
    > .side {}
    > .side.-left,
    > .side.-right {
        transform: translateX(0%);
    }
}