@import "../../../styles/variables";

.svi-chart {
	.chart-controller {
		.step {
			> .radio {
				margin-bottom: 20px;
				border: 1px solid $blue;
				border-radius: 4px;
				padding: 8px;
				max-height: 240px;
				overflow-y: auto;
				overflow-x: hidden;
				hr {
					border: 0;
					height: 1px;
					background: $blue;
				}
				.item {
					display: flex;
					margin-bottom: 12px;
					cursor: pointer;
					&.-primary {
						font-weight: 600;
					}
					&.-secondary {
						padding-left: 12px;
					}
					&.-selected {
						&:before {
							background: $blue;
						}
					}
					&:before {
						content: "";
						display: block;
						margin-top: 4px;
						margin-right: 8px;
						border: 1px solid $blue;
						min-width: 10px;
						height: 10px;
					}
					span {
						display: block;
						line-height: 1.2em;
					}
				}
			}
		}
	}
	.line-chart {
		min-height: 240px;
	}
}
