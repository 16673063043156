@import "../../../styles/variables";

.sitemap-section {
  padding-top: 110px;
  min-height: 90vh;
  .heading {
    text-align: center;
  }
  .lists {
    margin-top: 30px;
    width: 1300px;
  }
}
.sitemap-list {
  margin-bottom: 16px;
  padding-top: 10px;
  font-size: 15px;
	line-height: 24px;
	&:first-child {
		border-top: 2px solid $yellow;
	}
  > li > a,
  > li > span {
    font-weight: 600;
  }
	.title {
		margin-bottom: 16px;
	}
  ul li {
    margin-top: 6px;
    margin-bottom: 6px;
    > ul {
      padding-left: 10px;
    }
  }
  span {
    font-size: 18px;    
  }
  a {
    transition: all 0.3s ease;
    font-size: 16px;
  }
  a:hover {
    color: $yellow;
  }
}

ol,
ul {
    list-style: none;
}

a,
a:focus,
a:hover {
    text-decoration: none;
}

::-webkit-scrollbar {
    // display: none;
    /* Chrome Safari */
}

.sitemap-nav {
    max-width: 1140px;
    margin: auto;
}

.sitemap-nav .wrap>li {
    border-top: 2px solid #fdc100;
}

.map-title {
    font-size: 2.125rem;
    font-weight: 700;
    margin: auto;
    text-align: center;
    margin-bottom: 1rem;
}

.wrap .con-one {
    color: #000;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.wrap a:hover,
.wrap .con-three li a:hover {
    color: #fdc100;
}

.sitemap-nav .wrap>li {
    display: inline-block;
    box-sizing: border-box;
    margin: 1%;
    width: 44%;
    vertical-align: top;
}

.wrap .con-three {
    // margin-left: -1rem;
    margin-left: 10px;
}

.wrap .con-three li {
    margin-top: 1rem;
}

.wrap .con-three li a {
    font-size: 1rem;
    color: #000;
}

.wrap-ul>li {
    margin-top: 1rem;
}

.wrap .con-two {
    cursor: pointer;
    color: #000;
    font-weight: 700;
}

@media (max-width:576px) {
    .sitemap-nav .wrap>li {
        width: 100%;
    }
}
