@import "../../../styles/variables";
.statistics-datatable {
  display: none;
  width: 100%;
  overflow: auto;
  &.-active {
    display: block;
  }
  table {
    width: 100%;
    tr {
      border-bottom: 1px solid hsl(0, 0%, 90%);
      width: 100%;
      &:first-child {
        border-top: 1px solid hsl(0, 0%, 90%);
      }
    }
    td {
      padding: 8px 12px;
      font-family: 'Roboto Mono';
      font-size: 15px;
      white-space: nowrap;
      &:not(:first-child) {
        text-align: right;
      }
    }
  }
}
