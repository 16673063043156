.react-svg-zoom-map {
  overflow: hidden;
}

.react-svg-zoom-map .controls {
  position: absolute;
  z-index: 2
}

.react-svg-zoom-map .labels {
  position: absolute;
  z-index: 1
}

.react-svg-zoom-map > svg {
  display: block;
  overflow: visible;
  position: relative;
  z-index: 0;
}

.react-svg-zoom-map .map-item.-hide,
.react-svg-zoom-map .map-item.-disable {
  opacity: .2;
}
.react-svg-zoom-map .map-item-path {
  fill: #eee;
  stroke: #000;
  stroke-width: 0.5;
  transition: fill 0.3s ease;
  cursor: pointer;
}

.react-svg-zoom-map .map-item-path:hover {
  fill: #aaa;
}

.react-svg-zoom-map .map-g {
  /* will-change: transform; */
}

.react-svg-zoom-map .pin {
  fill: #FF6F6E;
  stroke: transparent;
  stroke-width: 0;
  cursor: pointer;
  transition: all .1s ease;
}
.react-svg-zoom-map .pin.-active {
  fill: #D10000;
  stroke: hsla(0, 100%, 41%, .5);
}
.react-svg-zoom-map .pin.-layer-0 {
  pointer-events: none;
}
