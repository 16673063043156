@import "../../../styles/variables";

.search-bar {
  padding: 6px 10px;
  border: 1px solid;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  color: $gray;

  transition: box-shadow 0.3s;
  box-shadow: 0px 0px 0px 0px $gray;

  &::before {
    font-size: 1.3em;
    transform: scale(0);
    transition: .1s;
    width: 0px;
  }

  &.-focus::before {
    transform: scale(1);
  }

  &.-submited {
    box-shadow: 0px 0px 0px 3px rgba($gray, 0.5);
  }

  >input {
    border: none;
    outline: none;
    background-color: transparent;
    color: currentColor;
    margin-left: 5px;
    width: auto;
    line-height: inherit;

    &:active {
      outline: none;
    }

    &.-submited {
      color: $black;
    }
  }

  >.button-cancel {
    color: currentColor;
    font-size: 1.3em;
    line-height: 27px;
  }

  &:not(.-focus) >input {
    width: 100%;
  } 
}