@import "../../../styles/variables";
.estimate-description {
  margin-bottom: 40px;
}
.estimate-heading {
  &.-active .estimate-selector {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $gray;
    background: $white;
    z-index: 3;
    transform: translateY(-100%);
    transition: all .3s ease;
    .buttonbar {
      margin: auto;
      @include for-size(phone) {
        max-width: 90%;
      }
      @include for-size(tablet) {
        max-width: 932px;
      }
      @include for-size(desktop) {
        max-width: 1112px;
      }
    }
  }
  &.-show .estimate-selector {
    transform: translateY(0);
  }
}
.estimate-selector {
  .buttonbar {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    margin-bottom: 40px;
    > button {
      display: block;
      border: 1px solid $pink;
      padding: 12px;
      width: 100%;
      font-weight: 600;
      transition: all .3s ease;
      text-align: center;
      &:hover, &.-active {
        background: $pink;
        color: $white;
      }
    }
  }
}
.estimate-section {
  position: relative;
  margin-bottom: 60px;
  border: 1px solid #979797;
  border-radius: 4px;
  @include for-size(phone) {
    display: block;
    padding: 18px 16px;
  }
  @include for-size(tablet) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 40px;
  }
  > .controller-bar {
    
    @include for-size(phone) {
      width: 100%;
      margin-bottom: 24px;
    }
    @include for-size(tablet) {
      margin-bottom: 0;
      width: 400px;
      max-width: 35%;
    }
    .heading {
      margin-bottom: 40px;
      color: $pink;
      font-size: 28px;
      font-weight: 600;
    }
    .controller {
      position: relative;
      margin-bottom: 40px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .step {
          font-weight: 600;
          font-size: 18px;
          span {
            margin-right: 8px;
          }
        }
        .toggle-btn {
          .open { display: inline-block; }
          .close {display: none; }
          &.-show {
            .open { display: none; }
            .close {display: inline-block; }
          }
          span {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
          }
          i {
            display: inline-block;
            vertical-align: middle;
            font-size: 28px;
            color: $pink;
          }
        }
      }
      .buttonbar {
        > button {
          display: block;
          margin-bottom: 10px;
          border: 1px solid $lightPink;
          padding: 12px;
          width: 100%;
          font-weight: 600;
          transition: all .3s ease;
          text-align: center;
          &:hover, &.-active {
            background: $lightPink;
          }
        }
      }
      .select {
        margin-bottom: 10px;
        border: 1px solid $pink;
        border-radius: 4px;
      }
      .input {
        margin-bottom: 40px;
        input {
          margin: 0 16px;
          border: 1px solid $lightPink;
          padding: 8px;
          width: 140px;
          height: 40px;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
        }
      }
    }
    .description {
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 22px;
      &.-toggle {
        max-height: 0;
        margin-bottom: 0;
        overflow: hidden;
        transition: all .3s ease;
        &.-open {
          margin-bottom: 40px;
          max-height: 400px;
        }
      }
    }
    .submit {
     > button {
        display: block;
        margin-bottom: 10px;
        border: 1px solid $black;
        border-radius: 4px;
        padding: 12px;
        width: 100%;
        background: $black;
        color: $white;
        font-weight: 600;
        transition: all .3s ease;
        text-align: center;
        &:not(:disabled):hover,
        &:not(:disabled).-active {
          border: 1px solid $pink;
          background: transparent;
          color: $black;
        }
        &:disabled {
          opacity: .5;
          cursor: not-allowed;
        }
      }
    }
    .advance {
      border: 1px solid #D8D8D8;
      padding: 24px 32px;
      > .title {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 600;
      }
      > .description {
        margin-bottom: 18px;
        line-height: 24px;
        font-size: 18px;
        font-weight: 300;
      }
      > .label {
        line-height: 24px;
        font-size: 18px;
        font-weight: 300;
      }
      > .select {
        margin-bottom: 24px;
        border: 1px solid $pink;
        border-radius: 4px;
      }
      > .submit {
        padding-top: 16px;
      }
    }
  }
  > .result-content {
    position: relative;
    @include for-size(phone) {
      width: 100%;
    }
    @include for-size(tablet) {
      width: 640px;
      max-width: 60%;
    }
    .heading {
      margin-bottom: 70px;
      color: $pink;
      font-size: 28px;
      font-weight: 600;
      line-height: 1;
    }
    .result {
      @include for-size(tablet) {
        min-height: 480px;
      }
    }
    .remark {
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .estimate-number {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
      border-top: 1px solid #979797;
      padding: 30px 0;
      .label {
        font-weight: 600;
        font-size: 22px;
      }
      .content {
        display: flex;
        align-items: center;
        position: relative;
        color: $pink;
        .value {
          display: block;
          margin-top: -12px;
          margin-right: 16px;
          line-height: 1;
          font-size: 120px;
          line-height: 120px;
          font-weight: 600;
          &.-sm {
            font-size: 60px;
          }
        }
        .unit {
          display: block;
          font-size: 22px;
        }
        .face {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .estimate-source {
      .item {
        line-height: 1.67;
        font-size: 14px;
      }
    }
    .estimate-material {
      position: relative;
      margin-bottom: 80px;
      .title {
        margin-bottom: 24px;
        font-size: 22px;
        font-weight: 600;
      }
      .info-controller {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 20px;
        color: $pink;
        transition: all .3s ease;
        &.-show {
          transform: rotate(45deg);
        }
      }
      .info-list {
        opacity: 0;
        max-height: 0;
        transition: all .3s ease;
        pointer-events: none;
        &.-open {
          opacity: 1;
          max-height: 1600px;
          pointer-events: all;
        }
        > .list {
          border: 1px solid $pink;
          border-radius: 4px;
          padding: 20px;
          background: $white;
          transition: all .3s ease;
          overflow: hidden;
          box-sizing: border-box;
          > .item {
            margin-bottom: 18px;
            font-size: 14px;
            .line {
              span, p {
                display: inline;
              }
            }
          }
        }
      }
      .material-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        > .item {
          padding: 10px;
          width: 50%;
        }
      }
    }
    .material-source {
      .title {
        margin-bottom: 24px;
        font-size: 22px;
        font-weight: 600;
      }
      .item {
        line-height: 1.67;
        font-size: 14px;
      }
    }
    .material-item {
      display: flex;
      align-items: center;
      padding: 10px;
      box-shadow: 1px 1px 2px hsla(0, 0%, 0%, .5);
      > .icon {
        margin-right: 8px;
        width: 68px;
        height: 68px;
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      > .info {
        display: inline-block;
        vertical-align: middle;
        .name {
          display: block;
          font-size: 18px;
          margin-right: 3px;
          margin-bottom: 4px;
        }
        .value {
          display: block;
          vertical-align: middle;
          .qty {
            display: inline-block;
            margin-top: -3px;
            font-weight: 600;
            font-size: 30px;
          }
          .unit {
            display: inline-block;
            margin-left: 3px;
            font-size: 20px;
          }
        }
      }
    }
  }
}
.estimate-download {
  margin-bottom: 80px;
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 520px;
    max-width: 100%;
    height: 80px;
    font-weight: 600;
    font-size: 20px;
  }
}