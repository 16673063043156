@import "../../../styles/variables";

.homepage-overview {
    min-height: 480px;
    li {
        margin-bottom: 30px;
    }
    li > .-lg {
        margin-bottom: 5px;
        letter-spacing: 1px;
        font-weight: 600;
    }
    li > .-md {
        letter-spacing: .5px;
    }
}