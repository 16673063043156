@import "../../../styles/variables";

.map-area {
    padding-top: 120px;
    min-height: 90vh;
    overflow: hidden;
    .step-status {
      position: relative;
      z-index: 2;
      .icon {
        display: flex;
        justify-content: center;
      }
      .icon > .step {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin: 0 24px;
        width: 48px;
        height: 48px;
        background: #AFAFAF;
        &.-active {
          background: $green;
          box-shadow: 0px 0px 0px 3px $white,
                      0px 0px 0px 4px $green;

        }
        span {
          font-size: 18px;
          font-weight: 600;
          color: $white;
        }
      }
      .status {
        margin-top: 30px;
        text-align: center;
        font-weight: 600;
        .-ch {
          margin-left: 10px;
        }
      }
    }
}
.map-selector {
  .bubble-info {
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    &.-show {
      visibility: visible;
      opacity: 1;
    }
    .bubble {
      position: absolute;
      border-radius: 4px;
      padding: 22px;
      width: 350px;
      background: $white;
      box-shadow: 2px 2px 4px hsla(0, 0%, 0%, .25);
      &.-right { left: 0 }
      &.-left  { right: 0 }
      &.-top   { bottom: 0 }
      &.-bottom{ top: 0 }
      .name {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .taiwan-map {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
    overflow: hidden;
    transition: all .3s ease;
    &.-show {
      opacity: 1;
      visibility: visible;
    }
    .controls {
      button {
        border-radius: 4px;
        padding: 4px 8px;
        background: $green;
        color: $white;
      }
    }
    .labels {
      display: none;
    }
    svg {
      &.all {
        .pin {
          display: none;
        }
      }
      .pin {
        fill: $green !important;
        &.-active {
          stroke: rgba($green, .5);
        }
      }
    }
  }
  .list {
    margin-top: 60px;
  }
  .list .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 8px 0;
    border-bottom: 1px solid $black;
    .more {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $black;
      border-radius: 4px;
      width: 180px;
      height: 40px;
      font-weight: 600;
    }
  }
  .list .tag {
    clear: both;
    float: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 36px;
    padding: 6px 15px;
    min-width: 120px;
    background: #F1F1F1;
  }
  .submit {
    position: relative;
    margin: auto;
    margin-bottom: 48px;
    width: 250px;
    z-index: 1;
  }
}
.svg-map {
  width: 100%;
  height: auto;
  path {
    fill: transparent;
  }
  .city {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: hsl(0, 0%, 60%);
    stroke-width: .3;
    fill: rgba(0, 0%, 0%, 0); 
    transition: all .3s ease;
  }
  .city.enable {
    stroke: $yellow;
    stroke-width: .5;
    fill: rgba($yellow, .1); 
    cursor: pointer;
  }
  .city.enable:hover {
    fill: rgba($yellow, .5);
  }
  .city.enable.-selected {
    fill: $yellow;
  }
  .county {
    stroke: hsla(0, 0%, 0%, .2);
    stroke-width: .2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .house {
    fill: $green;
    cursor: pointer;
    &.-disable {
      fill: $gray;
      cursor: not-allowed;
    }
  }
}