@import "../../../styles/variables";

.article-list-page {
    .article-section {
        @include for-size(phone) {
            padding-top: 12px;
            padding-bottom: 12px;
        }
        @include for-size(tablet) {
            padding-top: 36px;
            padding-bottom: 36px;
        }
        @include for-size(desktop) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
    .article-heading {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 400px;
        background-size: cover;
        background-position: center center;
        text-align: center;
        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 6px;
            z-index: 1;
        }
        &.green:before {
            background: $green;
        }
        &.blue:before {
            background: $blue;
        }
        &.red:before {
            background: $red;
        }
        &.pink:before {
            background: $pink;
        }
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 100%;
            background: rgba($black, .33);
            z-index: 0;
        }
        > .mainheading {
            margin-bottom: 12px;
            font-weight: 600;
            color: $white;
            z-index: 2;
        }
        > .subheading {
            font-weight: 600;
            color: $white;
            z-index: 2;
        }
    }
    .subtitle-block {
        position: relative;
        .select{
            margin-left: 10px;
            border-radius: 5px;
            height: 30px;
            font-size: 14px;
        }
    }
    .search-bar {
        border: 1px solid #747474;
        border-radius: 20px;
        @include for-size(phone) {
            width: 100%;
            margin-top: 20px;
        }
        @include for-size(tablet) {
            margin-top: 0px;
            margin-left: auto;
            width: 250px;
            position: absolute;
            bottom: 12px;
            right: 0px;
        }
    }
    .query-indicator {
        text-align: center;
        min-height: calc(100vh - 747px);
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.custom-select-rd {
    position: relative;
    width: 160px;
    display: inline-block;
    border: 1px solid #fdc100;
    border-radius: 4px;
    line-height: 30px;
    height: 40px;
    margin-left: 10px;
	> label {
		display: block;
		margin-bottom: 5px;
    }
    > .wrap > .select {
        appearance: none;
        display: block;
        border: 0;
        border-radius: 2px;
        padding: 8px 12px;
        padding-right: 24px;
        width: 90%;
        height: 38px;
        background: $white;
        font-weight: inherit;
        font-size: 15px;
        &:focus {
            outline: none;
        }
    }
    > .wrap > select > option {
        font-size: 12px;
    }
    > .wrap {
        position: relative;
    }
    .wrap > .icon {
        position: absolute;
        top: 2px;
        right: 0;
        z-index: 2;
        font-size: 36px;
        pointer-events: none;
    }
    > .wrap.-danger {
        border: 1px solid $danger;
        box-sizing: border-box;
    }


    >.msg {
        &.-danger {
            color: $danger;
        }
    }
}