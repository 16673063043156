@import "../../../styles/variables";

.advance-chart {
    padding-top: 60px;
    min-height: 90vh;
}
.advance-app {
    .subjectselector {
        margin-top: 24px;
    }
    .listselector {
        margin-top: 24px;
    }
    .itemselector {
        margin-top: 24px;
    }
    .itemselector.-disable {
        display: none;
        // opacity: .5;
    }
    .chartselector {
        margin-top: 24px;
    }
    .heading {
        margin-bottom: 10px;
        font-weight: 800;
    }
}
.advance-heading {
    margin-top: 80px;
    > .type-text {
        display: inline-block;
        vertical-align: middle;
        font-weight: 800;
    }
    > .icon-button {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-size: 24px;
    }
    > .icon-button > .bubble {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        bottom: calc(100% + 12px);
        left: 50%;
        margin-bottom: -12px;
        padding: 5px 10px;
        min-width: 240px;;
        white-space: nowrap;
        background: $black;
        color: $white;
        transform: translateX(-50%);
        transition: all .3s ease;
    }
    > .icon-button:hover > .bubble {
        visibility: visible;
        opacity: 1;
        margin-bottom: 0;
    }
    > .icon-button > .bubble:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $black;
        transform: translateX(-50%);

    }
}
.subject {
    .type-text {
        text-align: center;
    }
}
.advance-subject {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2px;
    margin-right: -2px;
    > .item {
        padding: 5px 2px;
        width: 25%;
    }
    > .item > button {
        width: 100%;
    }
}
.advance-primarylist {
    display: none;
    max-height: 380px;
    overflow-y: auto;
    &.-active {
        display: block;
    }
    > .item {
        position: relative;
    }
    > .item > .title {
        position: relative;
        padding: 8px 0;
        width: 100%;
        background: $white;
        font-weight: 800;
        text-align: left;
    }
}
.advance-secondarylist {
    margin-bottom: 30px;
    > .item {
        border-bottom: 1px solid #DDDDDD;
    }
    > .item > .dragger {
        position: relative;
        padding: 3px 0;
        width: 100%;
        background: #F1F1F1;
        text-align: left;
        transition: all .3s ease;
        cursor: no-drop;
    }
    > .item > .dragger .icon {
        display: none;
        position: absolute;
        right: 2px;
        top: 2px;
        font-size: 28px;
        color: $green;
    }
    > .item > .dragger[draggable="true"] {
        background: $white;
    }
    > .item > .dragger[draggable="true"]:hover {
        color: $green;
        cursor: grab;
    }
    > .item > .dragger[draggable="true"]:active {
        color: $green;
        cursor: grabbing;
    }
    > .item > .dragger[draggable="true"]:hover .icon,
    > .item > .dragger[draggable="true"]:active .icon {
        display: block;
    }
}
.advance-dropper {
    position: relative;
    padding: 10px 15px;
    padding-right: 40px;
    min-height: 48px;
    > .remove {
        display: none;
        position: absolute;
        right: 10px;
        top: 8px;
        color: $green;
        font-size: 30px;
    }
    > .placehold {
        display: block;
        text-align: center;
    }
    &.-empty {
        border: 1px dashed $black;
    }
    &.-fill {
        border: 1px dashed transparent;
        border-radius: 24px;
        background-color: #F1F1F1;
    }
    &.-fill > .remove {
        display: block;
    }
    &.-fill > .placehold {
        display: none;
    }
    &.-droppable > .placehold {
        display: block;
    }
    &.-disable > .placehold {
        display: none;
    }
    &.-hold {
        background: #F1F1F1;
    }
    &.-droppable {
        border: 1px dashed $black;
        color: $black;
    }
    &.-droppable > .zone {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    &.-disable > .zone {
        display: none;
    }
}
.advance-charttype {
    > .list {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-left: -5px;
        margin-right: -5px;
        min-height: 100px;
    }
    > .list li {
        padding: 5px;
    }
    > .list li.-disable {
        display: none;
    }
    > .list li button {
        width: 60px;
        height: 60px;
        color: $green;
        font-size: 40px;
        box-shadow: inset 0px 0px 0px 1px $green;
        transition: all .3s ease;
    }
    > .list li button:hover {
        box-shadow: inset 0px 0px 0px 2px $green;
    }
    > .list li button.-active {
        background: $green;
        color: $white;
    }
}
.advance-submit {
    margin-top: 14px;
    margin-bottom: 35px;
    .submit {
        margin: auto;
        width: 250px;
    }
    .submit > button {
        min-height: 48px;
    }
    .submit > button > span {
        display: inline-block;
        vertical-align: middle;
    }
    .submit > button > .loading {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        font-size: 24px;
        animation: loadingAnimation 3s linear infinite;
        &.-hide {
            display: none;
        }
    }
}
.limit-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black, .8);
    color: $white;
    z-index: 99;
    @include for-size(mobile) {
        display: flex;
    }
    @include for-size(tablet-more) {
        display: none;
    }
}