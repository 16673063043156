@import "../../styles/variables";

.header-block {
	display: flex;
	justify-content: space-between;
	position: absolute;
	width: 100%;
	z-index: 150;
	@include for-size(phone) {
		padding: 10px 15px;
	}
	@include for-size(tablet) {
		padding: 20px 40px;
	}
}
.header-logo {
	position: relative;
	> .logo {
		display: inline-flex;
		vertical-align: middle;
		align-items: center;
		justify-items: center;
	}
	> .logo > svg {
		margin-right: 12px;
		width: 50px;
		height: 50px;
	}
	> .logo > .logotext {
		opacity: 1;
		transition: all .3s ease;
		@include for-size(phone) {
			display: none;
		}
		@include for-size(tablet) {
			display: block;
		}
	}
	> .logo > .logotext > .ch {
		margin-bottom: 4px;
		font-size: 18px;
		font-weight: 800;
		font-family: 'Pingfang TC', '微軟正黑體', 'Microsoft JhengHei';
		letter-spacing: 1px;
	}
	> .logo > .logotext > .en {
		font-size: 18px;
		font-weight: 800;
		font-family: 'Poppins', sans-serif;
		letter-spacing: 2.9px;
	}
	> .breadcrumb-block {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 60px;
		transition: all .3s ease;
		@include for-size(phone) {
			display: none;	
		}
		@include for-size(tablet) {
			display: flex;	
		}
	}
}
.header-logo:hover {
	> .breadcrumb-block {
		visibility: visible;
		opacity: 1;
	}
}
.header-navbar {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.language-list {
	justify-content: center;
	align-items: center;
	@include for-size(phone) {
		button.-active {
			display: none;
		}
	}
	@include for-size(tablet) {
		display: flex;
		button.-active {
			display: inline-block;
		}
	}
	> .languageitem {
		position: relative;
		padding: 6px;
		font-size: 28px;
		color: inherit;
	}
	> .languageitem:before {
		content: "";
		visibility: hidden;
		opacity: 0;
		position: absolute;
		bottom: 4px;
		left: 50%;
		margin-left: -10px;
		width: 20px;
		height: 2px;
		background: $green;
		transition: all .3s ease;
		transform: translateY(4px);
	}
	> .languageitem.-active:before,
	> .languageitem:hover:before {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}
}
.link-list {
	display: flex;
	justify-content: flex-end;
	> .linkitem {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 6px;
		padding: 6px;
		font-size: 28px;
		color: inherit;
		transition: all .3s ease;
	}
	> .linkitem > i {
		display: block;
		color: inherit;
	}
}

.header-block.-dark .tooltip {
	color: white;
}


.header-block.-white {
	a:hover,
	button:hover {
		color: $black;
	}
}

.header-block.-green {
	a:hover,
	button:hover {
		color: $green;
	}
}

.header-block.-blue {
	a:hover,
	button:hover {
		color: $blue;
	}
}

.header-block.-yellow {
	a:hover,
	button:hover {
		color: $yellow;
	}
}

.header-block.-red {
	a:hover,
	button:hover {
		color: $red;
	}
}

.header-block.-pink {
	a:hover,
	button:hover {
		color: $pink;
	}
}

.header-block.-light {
	*, .logotext {
		color: $white;
	}
}
.header-block.-dark {
	*, .logotext {
		color: $black;
	}
}