@import "../../../styles/variables";

.advance-result {
  margin-top: 80px;
}
.result-option {
  > .item {
    display: flex;
    margin-bottom: 18px;
    white-space: nowrap;
  }
  > .item > .itemtitle {
    margin-right: 10px;
    font-weight: 600;
  }
  > .item > .text {
    font-weight: 600;
  }
  > .item > .radiobar {
    display: flex;
    flex-wrap: wrap;
    >.radio {
      margin-right: 20px;
      margin-bottom: 4px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
  > .item > .radio {
    margin-right: 20px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  > .item > .radio.-hide {
    display: none;
  }
  > .subject {
    margin-bottom: 40px;
  }
  > .download {
    display: block;
    text-align: right;
  }
}
.result-draw {
  margin-bottom: 12px;
}
.result-info {
  margin-bottom: 12px;
  > p {
    margin-bottom: 5px;
    color: #505050;
  }
  > .item {
    margin-bottom: 5px;
    padding: 15px 25px;
    background: #f1f1f1;
    color: #505050;
  }
}
.result-back {
  margin-top: 40px;
  margin-bottom: 80px;
  .fill-button {
    display: block;
    text-align: center;
  }
}
.loading-popup {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, .75);
  z-index: 2;
  .icon-refresh {
    display: block;
    margin: auto;
    font-size: 56px;
    color: $green;
    animation: loadingAnimation 3s linear infinite;
  }
  &.-hide {
    display: none;
  }
}