@import "../../../styles/variables";
.article-page {
  padding-top: 135px;
  .article-heading {
    .datemonth {
      border-top: 4px solid $green;
      color: $green;
      &.-blue {
        border-color: $blue;
        color: $blue;
      }
      &.-green {
        border-color: $green;
        color: $green;
      }
      &.-red {
        border-color: $red;
        color: $red;
      }
      &.-pink {
        border-color: $pink;
        color: $pink;
      }
    }
    .datemonth > .month {
      display: block;
      padding-top: 10px;
      font-weight: 600;
    }
    .datemonth > .date {
      display: block;
      padding-top: 4px;
      font-weight: 800;
    }
    .minititle {
      margin-bottom: 10px;
      font-size: 14px;
    }
    .heading {
      margin-bottom: 40px;
      line-height: 40px;
      font-weight: 800;
    }
  }
  .article-content {
    margin-bottom: 100px;
    .image {
      margin-bottom: 40px;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .author,
    .author > label,
    .sorting > label {
      font-weight: 800;
      margin-right: 14px;
		}
		.sorting .tag {
      margin-right: 8px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
		}
    .author {
      margin-bottom: 8px;
    }
    .sorting {
      margin-bottom: 50px;
    }
    .content {
      min-height: 80vh;
    }
  }

  .-loading-block {
    box-shadow: 0 1.5em rgba($gray, 0.3) inset;
    color: transparent;

    &.empty-content {
      margin-bottom: 20px;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
