@charset "UTF-8";
@keyframes LogoAnimation {
  0% {
    opacity: 1; }
  50% {
    opacity: .33; }
  100% {
    opacity: 1; } }

@keyframes ScrollDownAnimation {
  0% {
    top: 0;
    opacity: 0; }
  20% {
    top: 20%;
    opacity: 1; }
  80% {
    top: 80%;
    opacity: 1; }
  100% {
    top: 100%;
    opacity: 0; } }

@keyframes loadingAnimation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes arrowRight {
  from {
    position: relative;
    left: -20px; }
  to {
    position: relative;
    left: 20px; } }

@keyframes arrow-animation {
  from {
    opacity: 1;
    transform: translateX(0); }
  to {
    opacity: 0;
    transform: translateX(8px); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.header-block {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 150; }
  @media (min-width: 0) {
    .header-block {
      padding: 10px 15px; } }
  @media (min-width: 768px) {
    .header-block {
      padding: 20px 40px; } }

.header-logo {
  position: relative; }
  .header-logo > .logo {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-items: center; }
  .header-logo > .logo > svg {
    margin-right: 12px;
    width: 50px;
    height: 50px; }
  .header-logo > .logo > .logotext {
    opacity: 1;
    transition: all .3s ease; }
    @media (min-width: 0) {
      .header-logo > .logo > .logotext {
        display: none; } }
    @media (min-width: 768px) {
      .header-logo > .logo > .logotext {
        display: block; } }
  .header-logo > .logo > .logotext > .ch {
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 800;
    font-family: 'Pingfang TC', '微軟正黑體', 'Microsoft JhengHei';
    letter-spacing: 1px; }
  .header-logo > .logo > .logotext > .en {
    font-size: 18px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 2.9px; }
  .header-logo > .breadcrumb-block {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 60px;
    transition: all .3s ease; }
    @media (min-width: 0) {
      .header-logo > .breadcrumb-block {
        display: none; } }
    @media (min-width: 768px) {
      .header-logo > .breadcrumb-block {
        display: flex; } }

.header-logo:hover > .breadcrumb-block {
  visibility: visible;
  opacity: 1; }

.header-navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.language-list {
  justify-content: center;
  align-items: center; }
  @media (min-width: 0) {
    .language-list button.-active {
      display: none; } }
  @media (min-width: 768px) {
    .language-list {
      display: flex; }
      .language-list button.-active {
        display: inline-block; } }
  .language-list > .languageitem {
    position: relative;
    padding: 6px;
    font-size: 28px;
    color: inherit; }
  .language-list > .languageitem:before {
    content: "";
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 4px;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    height: 2px;
    background: #2FC172;
    transition: all .3s ease;
    transform: translateY(4px); }
  .language-list > .languageitem.-active:before,
  .language-list > .languageitem:hover:before {
    visibility: visible;
    opacity: 1;
    transform: translateY(0); }

.link-list {
  display: flex;
  justify-content: flex-end; }
  .link-list > .linkitem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 6px;
    padding: 6px;
    font-size: 28px;
    color: inherit;
    transition: all .3s ease; }
  .link-list > .linkitem > i {
    display: block;
    color: inherit; }

.header-block.-dark .tooltip {
  color: white; }

.header-block.-white a:hover,
.header-block.-white button:hover {
  color: #222; }

.header-block.-green a:hover,
.header-block.-green button:hover {
  color: #2FC172; }

.header-block.-blue a:hover,
.header-block.-blue button:hover {
  color: #68AAFF; }

.header-block.-yellow a:hover,
.header-block.-yellow button:hover {
  color: #FDC100; }

.header-block.-red a:hover,
.header-block.-red button:hover {
  color: #FF5C2A; }

.header-block.-pink a:hover,
.header-block.-pink button:hover {
  color: #FF6F6E; }

.header-block.-light *, .header-block.-light .logotext {
  color: #FFF; }

.header-block.-dark *, .header-block.-dark .logotext {
  color: #222; }
