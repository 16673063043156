@import "../../../styles/variables";

.morakot-area-page {
  .subject-item {
    .house-chart {
      margin-bottom: 120px;
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}