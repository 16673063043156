@import "../../../styles/variables";

@keyframes jump {
    0% {
        transform: scale(1, .85);
    }
    15% {
        transform: translateY(-5%) scale(.85, 1.1)
    }
    30% {
        transform: translateY(-50%) scale(.9, 1.05)
    }
    50% {
        transform: translateY(-60%) scale(1)
    }
    70% {
        transform: translateY(-50%) scale(1)
    }
    90% {
        transform: scale(1, 1);
    }
    95% {
        transform: scale(1, .85);
    }
    100% {
        transform: scale(1, .85);
    }
}
.preload-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($white, .85);
  transition: all .3s ease;
  z-index: 1;
  .animation {
    position: relative;
    width: 64px;
    height: 64px;
    &.-green > div { background: $green }
    &.-blue > div { background: $blue }
    &.-red > div { background: $pink }
    div {
      display: inline-block;
      position: absolute;
      left: 6px;
      width: 13px;
      background: $gray;
      animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    div:nth-child(1) {
      left: 6px;
      animation-delay: -0.24s;
    }
    div:nth-child(2) {
      left: 26px;
      animation-delay: -0.12s;
    }
    div:nth-child(3) {
      left: 45px;
      animation-delay: 0;
    }
  }
  @keyframes lds-facebook {
    0% {
      top: 6px;
      height: 51px;
    }
    50%, 100% {
      top: 19px;
      height: 26px;
    }
  }
}