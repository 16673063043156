@import "../../../styles/variables";
.taiwan-map-other {
	position: absolute;
	top: 120px;
	left: 150px;
	z-index: 2;
  .kinmen {
		border: 1px solid #888;
		margin-bottom: 12px;
		padding: 12px;
		width: 160px;
		background: #FFF;
		svg {
			width: 100%;
			height: auto;
		}
	}
	.lianjiang {
		border: 1px solid #888;
		margin-bottom: 12px;
		padding: 12px;
		width: 75px;
		background: #FFF;
		svg {
			width: 100%;
			height: auto;
		}
	}
}
