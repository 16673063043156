@import "../../../styles/variables";

.new-sfaa-page {
  padding-top: 95px;
  min-height: 90vh;
  .sfaa-section {
    &.-gray {
      background: #f5f5f5;
    }
    @include for-size(phone) {
      padding: 54px 0;
    }
    @include for-size(tablet) {
      padding: 80px 0;
    }

    .sectiontitle {
      margin-bottom: 20px;
      border-bottom: 4px solid $green;
      padding: 15px 0;
      font-size: 28px;
      font-weight: 800;
      letter-spacing: 2px;
      text-align: center;
      @include for-size(phone) {
        font-size: 22px;
      }
      @include for-size(tablet) {
        font-size: 28px;
      }
    }
    .sectioncontent {
      margin-top: 28px;
      .strong {
        margin: 24px 0;
        font-weight: 800;
        @include for-size(phone) {
          font-size: 18px;
        }
        @include for-size(tablet) {
          font-size: 20px;
        }
      }
      .table {
        margin: 20px 0;
        overflow: auto;
      }
      p {
        font-size: 18px;
        line-height: 1.75em;
        @include for-size(phone) {
          font-size: 16px;
        }
        @include for-size(tablet) {
          font-size: 18px;
        }
      }
      > img {
        display: block;

        margin: 40px auto;
        max-width: 100%;
        height: auto;
      }
    }
  }
  .sfaa-heading {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    .block {
      display: block;
      position: relative;
      font-size: 28px;
      color: $white;
      font-weight: 800;
      background: $black;
      @include for-size(phone) {
        width: 100%;
        height: 200px;
      }
      @include for-size(tablet) {
        width: 50%;
        height: auto;
        min-height: 480px;
      }
      &:hover {
        .background {
          opacity: 0.5;
        }
      }
      .heading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        white-space: nowrap;
      }
      .background {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.75;
        transition: all 0.3s ease;
      }
    }
  }
  .sfaa-info {
    &.-gray {
      background: #f5f5f5;
    }
    > .container {
      margin: 0 auto;
      width: 780px;
      max-width: 100%;
    }
  }
  .sfaa-list {
    margin-top: 25px;
    font-size: 18px;
    li {
      position: relative;
      line-height: 1.8;
      a {
        color: $green;
      }
      > label {
        display: inline-block;
        vertical-align: middle;
        min-width: 110px;
        font-weight: 600;
      }
      > p {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .sfaa-toggle {
    margin-bottom: 24px;
    border-radius: 4px;
    .controller {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      line-height: 1.8;
      font-weight: 600;
      text-align: left;
      color: inherit;
      .open {
        display: block;
      }
      .close {
        display: none;
      }
      &.-open {
        .open {
          display: none;
        }
        .close {
          display: block;
        }
      }
      .text {
        font-weight: 600;
        @include for-size(phone) {
          font-size: 17px;
        }
        @include for-size(tablet) {
          font-size: 20px;
        }
      }
      .icon {
        color: $green;
        span {
          font-size: 16px;
        }
        i {
          margin-left: 12px;
          font-size: 20px;
        }
      }
    }
    .content {
      border-radius: 0 0 4px 4px;
      padding-top: 0;
      max-height: 0;
      font-size: 18px;
      line-height: 1.5;
      transition: all 0.3s ease;
      overflow: hidden;
      background: $white;
      color: $black;
      > p {
        padding: 18px 20px;
        .sm {
          font-size: 12px;
        }
      }
      &.-show {
        max-height: 3000px;
      }
      .image {
        text-align: center;
      }
      .table {
        overflow: auto;
      }
    }
  }
  .sfaa-summarytable {
    padding: 10px;
    background: #e9e9e9;
    table {
      width: 100%;
    }
    th {
      text-align: left;
    }
    th.sort,
    th.issue,
    th.research {
      font-weight: 600;
    }
    th.sort {
      @include for-size(phone) {
        width: 28px;
      }
      @include for-size(tablet) {
        width: 20%;
      }
    }
    th.issue,
    th.research {
      width: 40%;
    }
    tr {
      border-bottom: 1px solid #d8d8d8;
    }
    th,
    td {
      padding: 10px 2px;
      vertical-align: middle;
    }
    tbody td {
      line-height: 21px;
    }
    tbody td.subject {
      font-weight: 600;
    }
    .remark {
      margin-top: 20px;
      text-align: right;
    }
    .remark > i {
      margin-left: 12px;
      vertical-align: middle;
    }
    .remark > span {
      vertical-align: middle;
    }
  }
}
