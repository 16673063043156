// Generated by grunt-webfont
// Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css



$ncdr-icons-font-path : "/fonts/" !default;

@font-face {
	font-family:"ncdr-icons";
	src:url($ncdr-icons-font-path + "icons-font.eot?c0440c0c513b96d6c408c16e244e3b19");
	src:url($ncdr-icons-font-path + "icons-font.eot?c0440c0c513b96d6c408c16e244e3b19#iefix") format("embedded-opentype"),
		url($ncdr-icons-font-path + "icons-font.woff2?c0440c0c513b96d6c408c16e244e3b19") format("woff2"),
		url($ncdr-icons-font-path + "icons-font.woff?c0440c0c513b96d6c408c16e244e3b19") format("woff"),
		url($ncdr-icons-font-path + "icons-font.ttf?c0440c0c513b96d6c408c16e244e3b19") format("truetype"),
		url($ncdr-icons-font-path + "icons-font.svg?c0440c0c513b96d6c408c16e244e3b19#icons-font") format("svg");
	font-weight:normal;
	font-style:normal;
}


// Bootstrap Overrides
[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family:"ncdr-icons";
	display:inline-block;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}



// Icons


.icon-arrow-down:before {
	content:"\f101";
}



.icon-arrow-l-left:before {
	content:"\f102";
}



.icon-arrow-l-right:before {
	content:"\f103";
}



.icon-arrow-l-top:before {
	content:"\f104";
}



.icon-arrow-left:before {
	content:"\f105";
}



.icon-arrow-right:before {
	content:"\f106";
}



.icon-arrow-top:before {
	content:"\f107";
}



.icon-burger:before {
	content:"\f108";
}



.icon-ch:before {
	content:"\f109";
}



.icon-chart-bar:before {
	content:"\f10a";
}



.icon-chart-line:before {
	content:"\f10b";
}



.icon-chart-pie:before {
	content:"\f10c";
}



.icon-chart-scatter:before {
	content:"\f10d";
}



.icon-close-fill:before {
	content:"\f10e";
}



.icon-close:before {
	content:"\f10f";
}



.icon-double-arrow:before {
	content:"\f110";
}



.icon-download:before {
	content:"\f111";
}



.icon-drag:before {
	content:"\f112";
}



.icon-dropdown:before {
	content:"\f113";
}



.icon-en:before {
	content:"\f114";
}



.icon-facebook:before {
	content:"\f115";
}



.icon-grid:before {
	content:"\f116";
}



.icon-info-fill:before {
	content:"\f117";
}



.icon-info:before {
	content:"\f118";
}



.icon-line-app:before {
	content:"\f119";
}



.icon-logout:before {
	content:"\f11a";
}



.icon-member:before {
	content:"\f11b";
}



.icon-minus:before {
	content:"\f11c";
}



.icon-o-plus:before {
	content:"\f11d";
}



.icon-oino:before {
	content:"\f11e";
}



.icon-plus:before {
	content:"\f11f";
}



.icon-refresh:before {
	content:"\f120";
}



.icon-reset:before {
	content:"\f121";
}



.icon-search:before {
	content:"\f122";
}



.icon-star:before {
	content:"\f123";
}



.icon-success:before {
	content:"\f124";
}



.icon-symbol-chart:before {
	content:"\f125";
}



.icon-symbol-download:before {
	content:"\f126";
}



.icon-symbol-mouse:before {
	content:"\f127";
}



.icon-symbol-search:before {
	content:"\f128";
}



.icon-task:before {
	content:"\f129";
}



.icon-twitter:before {
	content:"\f12a";
}

