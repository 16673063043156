@import "../../../styles/variables";

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;

  .info-block {
    width: fit-content;

    > .info {
      line-height: 26px;
    }
  }

  > .info {
    margin-bottom: 40px;
    line-height: 26px;
    @include for-size(phone) {
      text-align: left;
    }
    @include for-size(tablet) {
      text-align: center;
    }
  }
  .formitem {
    margin-bottom: 20px;
  }
  .formitem > span {
    display: inline-block;
    margin-top: 8px;
    font-size: 14px;
    letter-spacing: 2px;
	}
	.formitem > .article {
		line-height: 1.5em;
	}
  .submit {
    display: block;
    width: 100%;
    height: 48px;
  }

  .-overflow {
    font-weight: bold;
    color: $danger;
  }
}
