@import "../../../styles/variables";

.social-block {
    background: #000;
    color: $white;
    text-align: center;
    @include for-size(phone) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    @include for-size(tablet) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    > .buttonbar {
        margin-top: 6px;
    }
    > .buttonbar > button {
        margin: 0 12px;
        padding: 3px 6px;
        color: $white;
        font-size: 40px;
        transition: all .3s ease;
    }
    > .buttonbar > button:hover {
        color: $green;
    }
    &.-pink {
        background: $pink;
        > .buttonbar > button:hover {
            color: #000;
        }
    }
    &.-blue {
        background: $blue;
        > .buttonbar > button:hover {
            color: #000;
        }
    }
    &.-green {
        background: $green;
        color: $black;
        > .buttonbar > button {
            color: $black;
        }
        > .buttonbar > button:hover {
            color: $white;
        }
    }
    &.-yellow {
        background: $yellow;
        > .buttonbar > button:hover {
            color: #000;
        }
    }
}