@import "../../../styles/variables";

.modal-container {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($black, 0.5);
  z-index: 2;
  .modal {
    margin: auto;
  }
  .modal:focus {
    outline: none;
	}
}
.modal-content {
  position: relative;
  min-width: 460px;
  min-height: 230px;
  padding: 30px 120px;
  background: $white;
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 36px;
  }
  .close.-yellow {
    color: $yellow;
  }
  .close.-blue {
    color: $blue;
  }
  .close.-green {
    color: $green;
  }
  .close.-red {
    color: $red;
  }
  .modalbody {
    margin-top: 50px;
  }
  .modalfooter {
    margin-top: 50px;
  }
  .modalbody.-left,
  .modalfooter.-left {
    text-align: left;
  }
  .modalbody.-center,
  .modalfooter.-center {
    text-align: center;
  }
  .modalbody.-right,
  .modalfooter.-right {
    text-align: right;
  }
}
