@import "../../../styles/variables";

.morakot-area-page {
  padding-top: 110px;
  min-height: 90vh;
  .morakot-area-heading {
    margin-bottom: 30px;
    border-bottom: 4px solid $green;
    padding: 18px 0;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }
  .morakot-area-intro {
    margin: auto;
    width: 900px;
    max-width: 100%;
    .description {
      margin-bottom: 50px;
      font-size: 18px;
    }
  }
  .subject-selector {
    .title {
      margin-top: 24px;
      margin-bottom: 15px;
      font-weight: 600;
      text-align: center;
    }
    .subjects {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;
      margin-bottom: 24px;
      .item {
        padding: 15px;
        width: 25%;
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid $green;
          width: 100%;
          height: 80px;
          font-size: 16px;
          font-weight: 600;
          color: #000;
          &:hover {
            background: $green;
            color: $white;
          }
        }
      }
    }
  }
}