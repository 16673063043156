@import "../../../styles/variables";

.member-page {
    padding-top: 110px;
    min-height: 90vh;
    background: #F1F1F1;
}
.member-section {
    margin-bottom: 80px;
    > .heading {
        margin-bottom: 40px;
        text-align: center;
    }
    > .description {
        margin-bottom: 20px;
    }
    > .description.-left {
        text-align: left;
    }
    > .description.-right {
        text-align: right;
    }
    > .description.-center {
        text-align: center;
    }
    > .social {
        margin-bottom: 20px;
    }
    > .slash {
        margin-bottom: 20px;
    }
}
.social-login {
    .button {
        display: block;
        padding: 12px;
        width: 100%;
        text-align: center;
        background: $white;
    }
    .button > img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
    .button > span {
        display: inline-block;
        vertical-align: middle;
        font-weight: 600;
        letter-spacing: 1px;
    }
    .hint {
        margin-top: 20px;
        text-align: center;
    }
    .button-width{
        max-width: 33%;
    }
}
.slash-or {
    position: relative;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
    &:before, &:after {
        content: "";
        position: absolute;
        top: 50%;
        width: calc(50% - 24px);
        height: 1px;
        background: #D8D8D8;
    }
    &:before {
        left: 0;
    }
    &:after {
        right: 0;
    }
}
.login-form {
    .checkbox {
        letter-spacing: 1px;
    }
    .forgot {
        letter-spacing: 1px;
    }
    .separate {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .submit {
        font-weight: 600;
    }
}