@import "../../../styles/variables";

.page-navigation {
  &.-green {
    background: $green;
  }
  &.-blue {
    background: $blue;
  }
  &.-pink {
    background: $pink;
  }
  &.-red {
    background: $red;
  }
  &.-yellow {
    background: $yellow;
  }
  background: $green;
  @include for-size(phone) {
    padding-top: 40px;
    padding-bottom: 10px;
  }
  @include for-size(tablet) {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  > .container {
    position: relative;
    min-height: 50px;
  }
  > .container > .back,
  > .container > .top,
  > .container > .prev,
  > .container > .next {
    display: inline-block;
    position: absolute;
    bottom: 40%;
  }
  > .container > .back,
  > .container > .top {
    left: 50%;
    transform: translateX(-50%);
  }
  > .container > .prev {
    left: 0;
  }
  > .container > .next {
    right: 0;
  }
  > .container > .prev,
  > .container > .next {
    @include for-size(phone) {
      display: none;
    }
    @include for-size(tablet) {
      display: block;
    }
  }
  > .container > .prev > .icon,
  > .container > .next > .icon {
    margin: 0px 8px;
    color: $white;
    font-size: 24px;
  }
  > .container > .prev:hover > .icon,
  > .container > .next:hover > .icon {
    color: $black;
  }
  > .container > .prev span,
  > .container > .next span {
    vertical-align: middle;
    font-weight: 600;
  }
  > .container > .back > .icon,
  > .container > .top > .icon {
    position: absolute;
    bottom: calc(100% + 4px);
    left: 50%;
    font-size: 24px;
    color: $white;
    transform: translateX(-50%);
    transition: all 0.3s ease;
  }
  > .container > .back:hover > .icon,
  > .container > .top:hover > .icon {
    color: $black;
  }
  > .container > .back > span,
  > .container > .top > span {
    font-weight: 600;
  }

  > .container > .back {
    @include for-size(phone) {
      display: none;
    }
    @include for-size(tablet) {
      display: inline-block;
    }
  }
  > .container > .top {
    @include for-size(phone) {
      display: inline-block;
    }
    @include for-size(tablet) {
      display: none;
    }
  }
}
