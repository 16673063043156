@import "../../../styles/variables";
@keyframes delayDisplay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.evacuation-map {
  position: relative;
  opacity: 0;
  animation: delayDisplay .15s ease .6s forwards;
  .bubble-info {
    .bubble {
      position: absolute;
      bottom: 24px;
      left: 0;
      border-radius: 4px;
      padding: 8px 16px;
      min-width: 100px;
      background: #505050;
      transform: translateX(-50%);
      pointer-events: none;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 16px solid #505050;
        transform: translateX(-50%);
      }
      .name {
        font-size: 14px;
        color: $lightGray;
      }
      .value {
        color: $white;
        font-size: 20px;
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }
  .range-mark {
    position: absolute;
    top: 0;
    left: 0;
    .title {
      margin-bottom: 15px;
      font-size: 14px;
    }
    .range {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    .color {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
    .value {
      font-size: 14px;
      font-family: 'Roboto Condensed';
      line-height: 14px;
    }
  }
  .taiwan-map {
    position: relative;
    padding: 0 120px;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;
    &.-show {
      visibility: visible;
      opacity: 1;
    }
    .react-svg-zoom-map {
      pointer-events: visibleFill;
      height: 650px;
      .labels, .controls { display: none }
    }
    .svi-png {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        height: 600px;
        width: auto;
      }
    }
  }
}
