@import "../../styles/variables";

.footer-block {
    background: $black;
    @include for-size(phone) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @include for-size(tablet) {
        height: 147px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .update {
      color: $white;
      text-align: center;
    }
}

.copy-right {
    color: $white;
    text-align: center;
    font-weight: 600;
}
.subscribe-links {
    text-align: center;
    color: $white;
    a {
        padding-left: 20px;
        padding-right: 20px;
    }
    a:first-child {
        border-right: 1px solid $white;
    }
}