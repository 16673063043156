@import "../../styles/variables";

.news-page {
  padding-top: 110px;

  .query-indicator {
    text-align: center;
    margin-top: 20vh;
    margin-bottom: 20vh;
  }

  .pagination {
    width: 100%;
    bottom: 50px;
    height: 100px;
  }
}

.news-section {
  
  @include for-size(phone) {
    min-height: calc(100vh - 296px);
  }

  @include for-size(tablet) {
    min-height: calc(100vh - 356px);
  }

  .news-container {
    transition: opacity 0.3s;
    &.-loading {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .heading {
    text-align: center;
    margin-bottom: 40px;
  }

  .filter-bar {
    @include for-size(phone) {
      flex-wrap: wrap;
    }

    @include for-size(tablet) {
      flex-wrap: nowrap;
      .filterbutton.-last {
        margin-right: 20px;
      }
    }
    
  }

  .search-bar {
    margin: 4px;
    border: 1px solid #747474;
    border-radius: 20px;

    @include for-size(phone) {
      margin-left: 4px;
      width: calc(80% + 8px);
    }

    @include for-size(tablet) {
      margin-left: auto;
      width: 160px;
    }

    @include for-size(desktop) {
      margin-left: auto;
      width: 250px;
    }
    
  }

  
}