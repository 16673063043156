@import "../../../styles/variables";

.morakot-area-page {
  .subject-buttonbar {
    .head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      button {
        border: 2px solid $black;
        border-radius: 4px;
        width: 180px;
        height: 40px;
        font-weight: 600;
      }
      p {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .subject-back {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    font-weight: 600;
    font-size: 20px;
    a {
      span {
        vertical-align: middle;
      }
      i {
        margin-right: 15px;
        vertical-align: middle;
        font-size: 26px;
      }
    }
  }
}