@import "../../../styles/variables";
.shelter-map {
  position: relative;
  padding: 24px;
  .bubble-info {
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    &.-show {
      visibility: visible;
      opacity: 1;
    }
    .bubble {
      position: absolute;
      border-radius: 4px;
      padding: 22px;
      width: 350px;
      background: $white;
      box-shadow: 2px 2px 4px hsla(0, 0%, 0%, .25);
      &.-right { left: 0 }
      &.-left  { right: 0 }
      &.-top   { bottom: 0 }
      &.-bottom{ top: 0 }
      .close {
        position: absolute;
        top: 12px;
        right: 18px;
        font-size: 26px;
        color: #D8D8D8;
      }
      .name {
        font-size: 14px;
        color: $lightGray;
      }
      .value {
        color: $black;
        font-size: 14px;
      }
    }
  }
  .taiwan-map {
    position: relative;
    .react-svg-zoom-map {
      pointer-events: visibleFill;
      height: 600px;
      .labels, .controls { display: none }
    }
  }
}
