// Colors
$white: #FFF;
$black: #222;
$blue: #68AAFF;
$yellow: #FDC100;
$green: #2FC172;
$lightGray: #F1F1F1;
$darkGray: #505050;
$gray: #AFAFAF;
$pink: #FF6F6E;
$lightPink: #FFADAC;
$red: #FF5C2A;

$success: #1F824D;
$warning: #EE6A2B;
$danger: #E80622;

// Animation
@keyframes LogoAnimation {
	0% {  opacity: 1; }
	50% {  opacity: .33; }
	100% {  opacity: 1; }
}
@keyframes ScrollDownAnimation {
	0% {
		top: 0;
		opacity: 0;
	}
	20% {
		top: 20%;
		opacity: 1;
	}
	80% {
		top: 80%;
		opacity: 1;
	}
	100% {
		top: 100%;
		opacity: 0;
	}
}
@keyframes loadingAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes arrowRight {
    from {
        position: relative;
        left: -20px;
    }
    to {
        position: relative;
        left: 20px;
    }
}
@keyframes arrow-animation {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(8px);
  }
}
@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}



// Icon fonts
@mixin useIconfont() {
  font-family:"ncdr-icons";
	display:inline-block;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}

// Responsive
@mixin for-size($size) {
  @if $size == phone {
    @media (min-width: 0) { @content; }
  }
  @else if $size == phone-more {
    @media (min-width: 576px) { @content; }
  }
  @else if $size == tablet {
    @media (min-width: 768px) { @content; }
  }
  @else if $size == tablet-more {
    @media (min-width: 992px) { @content; }
  }
  @else if $size == desktop {
    @media (min-width: 1200px) { @content; }
  }
}