@import "variables";

// Round Button
.round-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: transparent;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
  transition: all 0.3s ease;
  @include for-size(phone) {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  @include for-size(tablet) {
    width: 48px;
    height: 48px;
    font-size: 24px;
  }
}
.round-button.-yellow {
  background: $yellow;
}
.round-button.-green {
  background: $green;
}
.round-button.-blue {
  background: $blue;
}
.round-button.-red {
  background: $red;
}
.round-button.-pink {
  background: $pink;
}
.round-button.-dark {
  color: $white;
}
.round-button.-dark:hover {
  color: $black;
  background: transparent;
  border-color: $black;
}
.round-button.-light {
  color: $black;
}
.round-button.-light:hover {
  color: $white;
  background: transparent;
  border-color: $white;
}

// Ghost Button
.ghost-button {
  position: relative;
  padding: 6px;
  transition: all 0.3s ease;
  text-align: center;
  z-index: 0;
  > i {
    display: inline-block;
    vertical-align: middle;
  }
  > span {
    display: inline-block;
    vertical-align: middle;
  }
}
.ghost-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  z-index: -1;
  transition: all 0.3s ease;
}
.ghost-button.-active:before,
.ghost-button:hover:before {
  width: 100%;
}
.ghost-button.-black {
  box-shadow: inset 0px 0px 0px 1px $black;
  color: $black;
}
.ghost-button.-black.-active,
.ghost-button.-black:hover {
  color: $white;
}
.ghost-button.-black:before {
  background: $black;
}

.ghost-button.-yellow {
  box-shadow: inset 0px 0px 0px 1px $yellow;
  color: $black;
}
.ghost-button.-yellow.-active,
.ghost-button.-yellow:hover {
  color: $white;
}
.ghost-button.-yellow:before {
  background: $yellow;
}
.ghost-button.-green {
  box-shadow: inset 0px 0px 0px 1px $green;
  color: $black;
}
.ghost-button.-green.-active,
.ghost-button.-green:hover {
  color: $white;
}
.ghost-button.-green:before {
  background: $green;
}
.ghost-button.-blue {
  box-shadow: inset 0px 0px 0px 1px $blue;
  color: $black;
}
.ghost-button.-blue.-active,
.ghost-button.-blue:hover {
  color: $white;
}
.ghost-button.-blue:before {
  background: $blue;
}
.ghost-button.-pink {
  box-shadow: inset 0px 0px 0px 1px $pink;
  color: $black;
}
.ghost-button.-pink.-active,
.ghost-button.-pink:hover {
  color: $white;
}
.ghost-button.-pink:before {
  background: $pink;
}

// Fill Button
.fill-button {
  display: inline-block;
  padding: 12px 0;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease;
}
.fill-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.fill-button.-fit {
  padding: 12px 80px;
  width: auto;
}
.fill-button.-yellow {
  background: $yellow;
  color: $white;
}
.fill-button.-yellow:not([disabled]):hover {
  box-shadow: 0px 0px 0px 1px $yellow;
  background: transparent;
  color: $black;
}
.fill-button.-gray {
  background: $darkGray;
  color: $white;
}
.fill-button.-gray:not([disabled]):hover {
  box-shadow: 0px 0px 0px 1px $darkGray;
  background: transparent;
  color: $black;
}
.fill-button.-green {
  background: $green;
  color: $white;
}
.fill-button.-green:not([disabled]):hover {
  box-shadow: 0px 0px 0px 1px $green;
  background: transparent;
  color: $black;
}
.fill-button.-blue {
  background: $blue;
  color: $white;
}
.fill-button.-blue:not([disabled]):hover {
  box-shadow: 0px 0px 0px 1px $blue;
  background: transparent;
  color: $black;
}
.fill-button.-red {
  background: $red;
  color: $white;
}
.fill-button.-red:not([disabled]):hover {
  box-shadow: 0px 0px 0px 1px $red;
  background: transparent;
  color: $black;
}
.fill-button.-pink {
  background: $pink;
  color: $white;
}
.fill-button.-pink:not([disabled]):hover {
  box-shadow: 0px 0px 0px 1px $pink;
  background: transparent;
  color: $black;
}
.fill-button.-dark {
  background: $black;
  color: $white;
}
.fill-button.-dark:not([disabled]):hover {
  box-shadow: 0px 0px 0px 1px $black;
  background: transparent;
  color: $black;
}
.fill-button.-light {
  background: $white;
  color: $black;
}
.fill-button.-light:not([disabled]):hover {
  box-shadow: 0px 0px 0px 1px $white;
  background: transparent;
  color: $white;
}

// Text Button
.text-button {
  transition: all 0.3s ease;
  > i,
  > span {
    display: inline-block;
    vertical-align: middle;
  }
}
.text-button.-green-dark,
.text-button.-dark-green:hover {
  color: $green;
}
.text-button.-yellow-dark,
.text-button.-light-yellow:hover,
.text-button.-dark-yellow:hover {
  color: $yellow;
}
.text-button.-pink-dark,
.text-button.-light-pink:hover,
.text-button.-dark-pink:hover {
  color: $pink;
}
.text-button.-dark-green,
.text-button.-dark-yellow,
.text-button.-dark-pink,
.text-button.-yellow-dark:hover,
.text-button.-green-dark:hover {
  color: $black;
}

.text-button.-light-yellow {
  color: $white;
}

// Direction Button
.direction-button {
  display: inline-block;
  > .text {
    display: inline-block;
  }
  > .icon {
    display: inline-flex;
    transition: all 0.3s ease;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
  }
}
.direction-button.-prev:hover > .icon {
  transform: translateX(-10px);
}
.direction-button.-next:hover > .icon {
  transform: translateX(10px);
}
