@import "../../../styles/variables";

.breadcrumb-block {
    display: flex;
    flex-wrap: nowrap;
    padding: 12px 20px;
    background: $white;
    > .item {
        font-size: 18px;
        white-space: nowrap;
        color: $black;
    }
    > .item:not(:last-child):after {
        content: "/";
        display: inline-block;
        margin-left: 8px;
        margin-right: 8px;
    }
    > .item > a {
        color: inherit;
    }
    > .item > a > span {
        color: inherit;
        transition: all .3s ease;
    }
}