@import "../../../styles/variables";

.member-page {
  padding-top: 110px;
  min-height: 90vh;
  background: #f1f1f1;
}
.member-section {
  margin-bottom: 80px;
  > .heading {
    margin-bottom: 40px;
    text-align: center;
  }
  > .description {
    margin-bottom: 20px;
  }
  > .description.-left {
    text-align: left;
  }
  > .description.-right {
    text-align: right;
  }
  > .description.-center {
    text-align: center;
  }
  > .social {
    margin-bottom: 20px;
  }
  > .slash {
    margin-bottom: 20px;
  }
}
.social-login {
  .button,
  .social-logined {
    display: block;
    padding: 12px;
    width: 100%;
    text-align: center;
    background: $white;

    > img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    > span {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      letter-spacing: 1px;
    }
    > .profile-pic {
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
      border-radius: 50%;
      max-width: 50px;
    }
  }
}
.slash-or {
  position: relative;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 24px);
    height: 1px;
    background: #d8d8d8;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
}
.login-form {
  .checkbox {
    letter-spacing: 1px;
  }
  .forgot {
    text-decoration: underline;
    letter-spacing: 1px;
  }
  .separate {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  button.submit {
    font-weight: 600;
  }
}
.formitem {
  > .custom-input {
    > .display {
      padding: 8px 12px;
      font-weight: bold;
    }
  }
  > .remark {
    margin-top: 8px;
    font-size: 16px
  }
}
