@import "../../../styles/variables";
.svi-advance-title {
	margin-bottom: 20px;
	border-bottom: 4px solid $blue;
  padding: 15px 0;
  letter-spacing: 2px;
	font-size: 28px;
	font-weight: 600;
	text-align: center;
}
.svi-advance-header {
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 80px;
  width: 500px;
  max-width: 100%;
  .remark {
    margin-left: 84px;
    font-size: 14px;
    color: $red;
  }
  .form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .label {
      margin-right: 15px;
      min-width: 70px;
      font-size: 16px;
      font-weight: 600;
    }
    .input {
      border: 1px solid $blue;
      border-radius: 4px;
      padding: 12px;
      width: 100%;
    }
    .select-group {
      display: flex;
      align-items: center;
      width: 100%;
      .slice {
        margin: 0 20px;
      }
      .custom-select {
        border: 1px solid $blue;
        border-radius: 4px;
        width: 150px;
      }
      .lock-wrap {
        display: flex;
        align-items: center;
        border: 1px solid #68AAFF;
        border-radius: 4px;
        padding: 8px 12px;
        width: 150px;
        height: 50px;
        background: #FFF;
        font-weight: inherit;
        font-size: 16px;
      }
      button {
        margin-left: 8px;
        border-radius: 4px;
        width: 80px;
        height: 50px;
        background: #505050;
        color: $white;
        font-weight: 600;
        &:disabled {
					opacity: .5;
					cursor: not-allowed;
				}
      }
    }
    .radio-group {
      display: flex;
      width: 100%; 
    }
  }
  .submit {
    margin-top: 12px;
    text-align: center;
    button {
      margin-left: 8px;
      border-radius: 4px;
      width: 80px;
      height: 50px;
      background: #505050;
      color: $white;
      font-weight: 600;
      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }
}
.svi-advance-controller {
  margin-bottom: 40px;
  > .head {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
  }
  > .type {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .item {
      padding: 0 10px;
      width: 400px;
      button {
        display: block;
        margin-bottom: 10px;
        border: 1px solid $blue;
        border-radius: 4px;
        padding: 12px;
        width: 100%;
        font-weight: 600;
        &:hover, &.-active {
          color: $white;
          background: $blue;
        }
      }
      .description {
        line-height: 1.5;
      }
    }
  }
}
.svi-advance-item {
  > .head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #7C7C7C;
    padding: 20px 0;
    
    p {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    button {
      color: $blue;
      i {
        vertical-align: middle;
        margin-right: 8px;
        font-size: 24px;
      }
      span {
        vertical-align: middle;
      }
    }
  }
  > .list {
    .item {
      margin: 0 16px;
      border-bottom: 1px solid #7C7C7C;
    }
  }
  > .list .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    .icon-success {
      color: $blue;
    }
    .name {
      margin-left: 8px;
      i {
        vertical-align: middle;
        margin-right: 16px;
        font-size: 24px;
      }
      span {
        vertical-align: middle;
        font-size: 18px;
      }
    }
    .crud {
      transition: all .3s ease;
      button {
        margin-right: 15px;
        border-radius: 4px;
        width: 80px;
        height: 32px;
        background: #ECECEC;
        color: #7C7C7C;
        &.-hide {
          opacity: 0;
          visibility: hidden;
        }
        &.-show {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  > .list .full {
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    overflow: hidden;
    transition: all .3s ease;
    &.-hide {
      max-height: 0;
    }
    &.-show {
      padding: 14px 0;
      max-height: 500px;
    }
    > .name {
      margin-left: 8px;
      i {
        vertical-align: middle;
        margin-right: 16px;
        font-size: 24px;
      }
      span {
        vertical-align: middle;
        font-size: 18px;
      }
    }
    > .form {
      margin-bottom: 20px;
      .flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .remark {
        font-size: 14px;
        color: #505050;
      }
      .label {
        margin-right: 15px;
        min-width: 70px;
        font-size: 16px;
        font-weight: 600;
      }
      .input {
        border: 1px solid $blue;
        border-radius: 4px;
        padding: 12px;
        width: 100%;
      }
      .select-group {
        display: flex;
        align-items: center;
        width: 100%;
        .slice {
          margin: 0 20px;
        }
        .custom-select {
          border: 1px solid $blue;
          border-radius: 4px;
          width: 150px;
        }
      }
      .radio-group {
        display: flex;
        width: 65%; 
      }
    }
    .description {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.75;
    }
    .buttonbar {
      display: flex;
      .upload {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border: 1px solid $blue;
        border-radius: 4px;
        margin-right: 20px;
        font-weight: 600;
        cursor: pointer;
        input {
          display: none;
        }
      }
      .submit {
        border-radius: 4px;
        width: 120px;
        height: 40px;
        background: #505050;
        color: $white;
        font-weight: 600;
        &:disabled {
					opacity: .5;
					cursor: not-allowed;
				}
        &.-sending:after {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 8px;
          height: 8px;
          margin-left: 8px;
          border-radius: 50%;
          background: $white;
          animation: jump .5s linear infinite;
        }
      }
    }
    .status {
      margin-top: 12px;
      height: 20px;
      font-size: 14px;
      color: $red;
    }
  }
}
.svi-advance-submit {
  margin-bottom: 100px;
  text-align: center;
  button, a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 4px;
    width: 780px;
    max-width: 100%;
    height: 50px;
    background: #505050;
    color: $white;
    font-size: 20px;
    font-weight: 600;
    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}
.svi-advance-result {
  .head {
    font-size: 18px;
    font-weight: 800;
  }
  .download {
    text-align: right
  }
  .result {
    position: relative;
    margin: auto;
    width: 900px;
    max-width: 100%;
    min-height: 45vh;
  }
  .remark {
    margin-top: 10px;
    line-height: 1.5em;
    font-size: 14px;
    text-align: center;
  }
  .back {
    margin-top: 40px;
    margin-bottom: 100px;
    text-align: center;
    button {
      margin-left: 8px;
      border-radius: 4px;
      width: 400px;
      height: 50px;
      background: #505050;
      color: $white;
      font-weight: 600;
      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }

  .bottom {
    .bottom-content {
      padding: 0 12px;
      padding-top: 32px;
    }
    .info {
      margin-bottom: 12px;
      > .item {
        margin-bottom: 5px;
        padding: 15px 25px;
        background: #f1f1f1;
        color: $darkGray;
        .subitem {
          margin-top: 5px;
          padding: 5px 0;
          border-top: 1px solid $gray;
          &:first-child {
            border: 0;
          }
        }
      }
    }
  }
}

.svi-advance-table {
	border: 1px solid #ADADAD;
	border-radius: 4px;
	padding: 5px;
	padding-bottom: 3px;
	min-width: 740px;
	background: $white;
  .-row {
    display: flex;
    flex-direction: row;
		align-items: stretch;
  }
  .-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tabs {
    .county, .town {
      margin-right: 6px;
      padding: 3px 12px;
      border-radius: 4px 4px 0px 0px;
      color: $white;
      background: #6B6B6B;
      font-size: 14px;
      &.-active {
        background: #4B4B4B;
      }
    }
  }
  .head {
		display: flex;
		margin-bottom: 2px;
		color: $white;
		background: #4B4B4B;
		> li {
			padding: 8px 0;
			text-align: center;
		}
		> li:first-child {
      width: 140px;
    }
		> li:nth-child(2) {
			padding: 8px 8px;
      width: calc(100% - 410px);
      text-align: left;
		}
		> li:nth-child(3) {
      width: 170px;
    }
		> li:last-child {
      width: 100px;
		}
	}
  .body {
    li {
      width: 100%;
    }
    .primary {
			margin-bottom: 2px;
			width: 100%;
			&:nth-child(1) > .item { background: $blue; }
			&:nth-child(1) .secondary, &:nth-child(1) .thirdary { border-color: $blue; }
			&:nth-child(2) > .item { background: $green; }
			&:nth-child(2) .secondary, &:nth-child(2) .thirdary { border-color: $green; }
			&:nth-child(3) > .item { background: $yellow; }
			&:nth-child(3) .secondary, &:nth-child(3) .thirdary { border-color: $yellow; }
			&:nth-child(4) > .item { background: $pink; }
			&:nth-child(4) .secondary, &:nth-child(4) .thirdary { border-color: $pink; }
      > .item {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 6px 0;
				width: 140px;
				color: $white;
				font-weight: 600;
      }
      > .layer {
        width: calc(100% - 140px);
      }
    }
    .secondary {
      display: flex;
			border-bottom: 1px solid;
			background: $white;
			> .item {
				padding: 6px 0px;
				width: 120px;
        text-align: center;
        &:first-child {
  				padding: 6px 8px;
          width: calc(100% - 270px);
          text-align: left;
        }
        &:nth-child(2) {
          width: 170px;
        }
        &:last-child {
          width: 100px;
        }
			}
      > .layer {
				padding: 6px 0;
        width: calc(100% - 120px);
      }
		}
  }
}