@import "variables";

//---------------------------
// Typography
//---------------------------

$fontScale: 1;

html, body {
  line-height: 1.5em;
  font-size: (16px * $fontScale);
  margin: 0;
  padding: 0;
  font-family: 'Poppins', '微軟正黑體', 'Microsoft JhengHei', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, textarea, select, option, button, a.drop-button {
  font-family: 'Pingfang TC', '微軟正黑體', 'Microsoft JhengHei', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.link-text {
    text-decoration: underline;
}

.type-heading {
    &.-en {
        font-family: 'Poppins', '微軟正黑體', 'Microsoft JhengHei', sans-serif;
    }
    &.-ch {
        font-family: 'Pingfang TC', '微軟正黑體', 'Microsoft JhengHei', sans-serif;
    }
    @include for-size(phone) {
        &.-en.-h1 {
            font-size: (28px * $fontScale);
        }
        &.-en.-h2 {
            font-size: (36px * $fontScale);
        }
        &.-en.-h3 {
            font-size: (18px * $fontScale);
        }
        &.-en.-h4 {
            font-size: (20px * $fontScale);
        }
        &.-en.-h5 {
            font-size: (18px * $fontScale);
        }
        &.-en.-h6 {
            font-size: (20px * $fontScale);
        }
        &.-en.-h7 {
            font-size: (18px * $fontScale);
            font-weight: 600;
        }
        &.-en.-h8 {
            font-size: (16px * $fontScale);
            font-weight: 600;
        }

        &.-ch.-h1 {
            font-size: (28px * $fontScale);
        }
        &.-ch.-h2 {
            font-size: (36px * $fontScale);
        }
        &.-ch.-h3 {
            font-size: (18px * $fontScale);
        }
        &.-ch.-h4 {
            font-size: (20px * $fontScale);
        }
        &.-ch.-h5 {
            font-size: (18px * $fontScale);
        }
        &.-ch.-h6 {
            font-size: (20px * $fontScale);
        }
    }
    @include for-size(tablet) {
        &.-en {
            font-weight: 800;
        }
        &.-ch {
            font-weight: 600;
        }
        &.-en.-h1 {
            font-size: (42px * $fontScale);
        }
        &.-en.-h2 {
            font-size: (38px * $fontScale);
        }
        &.-en.-h3 {
            font-size: (34px * $fontScale);
        }
        &.-en.-h4 {
            font-size: (30px * $fontScale);
        }
        &.-en.-h5 {
            font-size: (26px * $fontScale);
        }
        &.-en.-h6 {
            font-size: (22px * $fontScale);
        }
        &.-en.-h7 {
            font-size: (18px * $fontScale);
            font-weight: 600;
        }
        &.-en.-h8 {
            font-size: (16px * $fontScale);
            font-weight: 600;
        }

        &.-ch.-h1 {
            font-size: (42px * $fontScale);
        }
        &.-ch.-h2 {
            font-size: (38px * $fontScale);
        }
        &.-ch.-h3 {
            font-size: (34px * $fontScale);
        }
        &.-ch.-h4 {
            font-size: (30px * $fontScale);
        }
        &.-ch.-h5 {
            font-size: (26px * $fontScale);
        }
        &.-ch.-h6 {
            font-size: (22px * $fontScale);
        }
    }
}

.type-text {
    &.-en {
        font-family: 'Roboto', '微軟正黑體', 'Microsoft JhengHei', sans-serif;
        font-weight: 400;
        line-height: (28px * $fontScale);
    }
    &.-ch {
        font-family: 'Pingfang TC', '微軟正黑體', 'Microsoft JhengHei', sans-serif;
        // font-weight: 400;
        line-height: (28px * $fontScale);
        letter-spacing: 1px;
    }
    @include for-size(phone) {
        &.-en.-lg {
            font-size: (18px * $fontScale);
        }
        &.-en.-md {
            font-size: (18px * $fontScale);
        }
        &.-en.-sm {
            font-size: (15px * $fontScale);
        }
        &.-en.-xs {
            font-size: (14px * $fontScale);
        }
        &.-en.-ss {
            font-size: (13px * $fontScale);
        }

        &.-ch.-lg {
            font-size: (18px * $fontScale);
        }
        &.-ch.-md {
            font-size: (18px * $fontScale);
        }
        &.-ch.-sm {
            font-size: (15px * $fontScale);
        }
        &.-ch.-xs {
            font-size: (14px * $fontScale);
        }
        &.-ch.-ss {
            font-size: (13px * $fontScale);
        }
    }
    @include for-size(tablet) {
        &.-en.-lg {
            font-size: (20px * $fontScale);
        }
        &.-en.-md {
            font-size: (18px * $fontScale);
        }
        &.-en.-sm {
            font-size: (17px * $fontScale);
        }
        &.-en.-xs {
            font-size: (16px * $fontScale);
        }
        &.-en.-ss {
            font-size: (15px * $fontScale);
        }

        &.-ch.-lg {
            font-size: (20px * $fontScale);
        }
        &.-ch.-md {
            font-size: (18px * $fontScale);
        }
        &.-ch.-sm {
            font-size: (17px * $fontScale);
        }
        &.-ch.-xs {
            font-size: (16px * $fontScale);
        }
        &.-ch.-ss {
            font-size: (15px * $fontScale);
        }
    }

}
