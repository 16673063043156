@import "../../../styles/variables";

.evacuation-chart {
	.chart-controller {
		.basic {
			margin-bottom: 50px;
		}
		.step {
			margin-bottom: 20px;
			> .radio {
				margin-bottom: 20px;
				border: 1px solid $pink;
				border-radius: 4px;
				padding: 8px;
				max-height: 240px;
				overflow-y: auto;
				overflow-x: hidden;
				hr {
					border: 0;
					height: 1px;
					background: $pink;
				}
				.item {
					display: flex;
					margin-bottom: 12px;
					cursor: pointer;
					&.-primary {
						font-weight: 600;
					}
					&.-secondary {
						padding-left: 12px;
					}
					&.-selected {
						&:before {
							background: $pink;
						}
					}
					&:before {
						content: "";
						display: block;
						margin-top: 4px;
						margin-right: 8px;
						border: 1px solid $pink;
						min-width: 10px;
						height: 10px;
					}
					span {
						display: block;
						line-height: 1.2em;
					}
				}
			}
			> .title {
				margin-bottom: 10px;
				font-size: 18px;
				font-weight: 600;
				.en {
					margin-right: 12px;
					font-family: "Roboto";
				}
			}
			> .buttonbar {
				a {
					display: block;
					margin-bottom: 10px;
					border: 1px solid $pink;
					border-radius: 4px;
					padding: 12px;
					width: 100%;
					font-weight: 600;
					transition: all .3s ease;
					text-align: center;
					&:hover, &.-active {
						background: $pink;
						color: $white;
					}
				}
			}
			> .info {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: 400;
				line-height: 1.5;
				> .reset {
					color: $pink;
					font-family: "Roboto";
					i {
						margin-right: 4px;
					}
				}
			}
			> .select {
				margin-bottom: 10px;
				border: 1px solid $pink;
				border-radius: 4px;
				font-weight: 600;
			}
		}
		.submit {
			margin-bottom: 10px;
			text-align: center;
			button {
				border-radius: 4px;
				padding: 12px;
				width: 160px;
				height: 60px;
				background: #505050;
				color: $white;
				font-size: 20px;
				font-weight: 600;
				span {
					vertical-align: middle;
				}
				&:disabled {
					opacity: .5;
					cursor: not-allowed;
				}
				&.-loading {
					&:after {
						content: "";
						display: inline-block;
						vertical-align: middle;
						width: 8px;
						height: 8px;
						margin-left: 8px;
						// border: 4px solid $white;
						border-radius: 50%;
						background: $white;
						animation: jump .5s linear infinite;
					}
				}
			}
		}
	}
	.chart-result {
		.heading {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 14px;
			.title {
				font-size: 20px;
				font-weight: 600;
			}
		}
		.chart {
			position: relative;
			padding-bottom: 20px;
			&.-show {
				display: block;
			}
			&.-hide {
				display: none;
			}
			svg {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		.info {
			margin-bottom: 20px;
			> .title {
				padding-top: 10px;
				margin-bottom: 20px;
				font-size: 18px;
				font-weight: 600;
			}
			> .subtitle {
				margin-bottom: 20px;
				font-size: 18px;
				font-weight: 600;
			}
			> .content {
				line-height: 1.5;

				a {
					color: $yellow;
					text-decoration: underline;
				}
			}
		}
		.origin {
			margin-bottom: 20px;
			> .title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 10px;
				.text {
					font-size: 18px;
					font-weight: 600;
				}
			}
			> .table {
				border: 1px solid $black;
				max-height: 400px;
				overflow: auto;
			}
			> .remark {
				margin-top: 12px;
				font-size: 14px;
			}
		}
	}
}
.page-link {
	position: fixed;
	top: 50%;
	right: 20px;
	z-index: 1;
	text-align: right;
	transform: translateY(-50%);
	@include for-size(phone) {
		display: none;
	}
	@include for-size(tablet) {
		display: block;
	}
	.item {
		clear: both;
		float: right;
		display: block;
		margin: 4px 0;
		cursor: pointer;
		&.-show, &:hover {
			&:after {
				background: $black;
			}
		}
		&:after {
			content: "";
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
			border: 1px solid $black;
			border-radius: 50%;
			width: 6px;
			height: 6px;
		}
		span {
			display: inline-block;
			vertical-align: middle;
			font-size: 15px;
		}
	}
}