@import "../../../styles/variables";

.morakot-area-page {
  .subject-item {
    margin-bottom: 120px;
    .item-filter {
      margin-bottom: 40px;
      .filter {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .label {
          margin-right: 20px;
          font-weight: 600;
        }
      }
    }
    .item-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .title {
        font-size: 20px;
        font-weight: 600;
      }
    }
    .item-description {
      color: #606060;
      margin-bottom: 20px;
    }
    .chart {
      margin-bottom: 20px;
      border: 1px solid #979797;
      > div {
        padding: 12px 0;
      }
    }
    .notes {
      font-size: 16px;
      color: #606060;
    }
  }
}