@import "../../../styles/variables";

.svi-page {
	padding-top: 95px;
	min-height: 800px;
}
.svi-section {
	@include for-size(phone) {
		padding: 54px 0;
	}
	@include for-size(tablet) {
		padding: 80px 0;
	}
}
.svi-heading {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 1;
  .block {
    display: block;
    position: relative;
	background: $black;
	@include for-size(phone) {
		width: 100%;
		height: 200px;
	}
	@include for-size(tablet) {
		width: 50%;
		height: auto;
	}
	&:hover {
		.background {
			opacity: .5;
		}
		.heading .subtitle {
			opacity: 1;
			transform: translateY(0);
		}
	}
    .heading {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		white-space: nowrap;
		.title {
			margin-top: 12px;
			font-size: 28px;
			color: $white;
			font-weight: 800;
			text-align: center;
		}
		.subtitle {
			opacity: 0;
			transform: translateY(100%);
			margin-top: 12px;
			text-align: center;
			font-size: 18px;
			color: $white;
			transition: all .3s ease;
		}
    }
    .background {
      display: block;
      width: 100%;
      height: 100%;
		object-fit: cover;
		opacity: 0.75;
		transition: all .3s ease;
    }
  }
}
.svi-intro {
	background: $blue;
	color: $white;
	> .container {
		margin: 0 auto;
		width: 780px;
		max-width: 100%;
	}
	.sectiontitle {
    padding: 15px 0;
    font-weight: 800;
    letter-spacing: 2px;
		text-align: center;
		@include for-size(phone) {
	    font-size: 22px;
		}
		@include for-size(tablet) {
	    font-size: 28px;
		}
  }
	.sectioncontent {
		line-height: 1.75;
		@include for-size(phone) {
	    font-size: 16px;
		}
		@include for-size(tablet) {
	    font-size: 18px;
		}
	}
}
.svi-info {
	> .container {
		margin: 0 auto;
		width: 780px;
		max-width: 100%;
	}
  &.-gray {
    background: $lightGray;
  }
  &.-blue {
		background: $blue;
		color: $white;
		.sectiontitle {
			border-bottom: 4px solid $white;
		}
	}
	&.-lightblue {
		background: #A4CCFF;
		.sectiontitle {
			border-bottom: 4px solid $white;
		}
	}
  .sectiontitle {
    border-bottom: 4px solid $blue;
    padding: 15px 0;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 2px;
		text-align: center;
		@include for-size(phone) {
	    font-size: 22px;
		}
		@include for-size(tablet) {
	    font-size: 28px;
		}
  }
  .sectioncontent {
    margin-top: 28px;
    .strong {
     	margin: 24px 0;
		font-weight: 800;
		@include for-size(phone) {
			font-size: 18px;
		}
		@include for-size(tablet) {
				font-size: 20px;
		}
	}
	.list {}
	.hint {
		text-align: center;
		color: $blue;
		font-size: 12px;
		font-weight: bold;
		@include for-size(phone) {
			display: block;
		}
		@include for-size(tablet) {
			display: none;
		}
	}
	.table {
		overflow: auto;
		@include for-size(tablet) {
			margin: 20px 0;
		}
	}
	.toggle {
		@include for-size(phone) {
			margin: 10px 0;
		}
		@include for-size(tablet) {
			margin: 20px 0;
		}
	}
    p {
      font-size: 18px;
			line-height: 1.75em;
			@include for-size(phone) {
				font-size: 16px;
			}
			@include for-size(tablet) {
      	font-size: 18px;
			}
    }
    > img {
      display: block;
			 
			margin: 40px auto;
      max-width: 100%;
      height: auto;
    }
  }
}
.svi-indicator-table {
	border: 1px solid #ADADAD;
	border-radius: 4px;
	padding: 5px;
	padding-bottom: 3px;
	min-width: 740px;
	background: $white;
  .-row {
    display: flex;
    flex-direction: row;
		align-items: stretch;
  }
  .-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tabs {
    .county, .town {
      margin-right: 6px;
      padding: 3px 12px;
      border-radius: 4px 4px 0px 0px;
      color: $white;
      background: #6B6B6B;
      font-size: 14px;
      &.-active {
        background: #4B4B4B;
      }
    }
  }
  .head {
		display: flex;
		margin-bottom: 2px;
		color: $white;
		background: #4B4B4B;
		li {
			padding: 8px 0;
			text-align: center;
		}
		li:first-child,
		li:nth-child(2) {
			width: 140px;
		}
		li:nth-child(3) {
			width: 350px;
			text-align: left;
		}
		li:last-child {
			width: calc(100% - 630px);
		}
	}
  .body {
    li {
      width: 100%;
    }
    .primary {
			margin-bottom: 2px;
			width: 100%;
			&:nth-child(1) > .item { background: $blue; }
			&:nth-child(1) .secondary, &:nth-child(1) .thirdary { border-color: $blue; }
			&:nth-child(2) > .item { background: $green; }
			&:nth-child(2) .secondary, &:nth-child(2) .thirdary { border-color: $green; }
			&:nth-child(3) > .item { background: $yellow; }
			&:nth-child(3) .secondary, &:nth-child(3) .thirdary { border-color: $yellow; }
			&:nth-child(4) > .item { background: $pink; }
			&:nth-child(4) .secondary, &:nth-child(4) .thirdary { border-color: $pink; }
      > .item {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 6px 0;
				width: 140px;
				color: $white;
				font-weight: 600;
      }
      > .layer {
        width: calc(100% - 140px);
      }
    }
    .secondary {
			border-top: 1px solid;
			background: $white;
      > .item {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 6px 0;
				width: 140px;
				text-align: center;
				font-weight: 600;
      }
      > .layer {
        width: calc(100% - 140px);
      }
    }
    .thirdary {
			border-bottom: 1px solid;
			background: $white;
			&:last-child {
				border: 0;
			}
			> .item {
				padding: 6px 0;
				width: 120px;
				text-align: center;
			}
      > .layer {
				padding: 6px 0;
        width: calc(100% - 120px);
      }
		}
  }
}
.svi-detial-table {
	border: 1px solid #ADADAD;
	border-radius: 4px;
	padding: 5px;
	padding-bottom: 3px;
	min-width: 740px;
	background: $white;
  .-row {
    display: flex;
    flex-direction: row;
		align-items: stretch;
  }
  .-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tabs {
    .county, .town {
      margin-right: 6px;
      padding: 3px 12px;
      border-radius: 4px 4px 0px 0px;
      color: $white;
      background: #6B6B6B;
      font-size: 14px;
      &.-active {
        background: #4B4B4B;
      }
    }
  }

  .head {
		display: flex;
		margin-bottom: 2px;
		color: $white;
		background: #4B4B4B;
		li {
			padding: 8px 2px;
			text-align: left;
			font-size: 14px;
			&:first-child { width: 40px; }
			&:nth-child(2) { width: 140px;}
			&:nth-child(3) { width: 55px;}
			&:nth-child(4) { width: 60px;}
			&:nth-child(5) { width: 115px;}
			&:nth-child(6) { width: calc(100% - 410px);}
		}
	}
  .body {
    li {
      width: 100%;
    }
    .primary {
			margin-bottom: 2px;
			width: 100%;
			&:nth-child(1) > .item { background: $blue; }
			&:nth-child(1) .secondary, &:nth-child(1) .thirdary { border-color: $blue; }
			&:nth-child(2) > .item { background: $green; }
			&:nth-child(2) .secondary, &:nth-child(2) .thirdary { border-color: $green; }
			&:nth-child(3) > .item { background: $yellow; }
			&:nth-child(3) .secondary, &:nth-child(3) .thirdary { border-color: $yellow; }
			&:nth-child(4) > .item { background: $pink; }
			&:nth-child(4) .secondary, &:nth-child(4) .thirdary { border-color: $pink; }
      > .item {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 6px 8px;
				width: 40px;
				min-height: 80px;
				writing-mode: vertical-lr;
				letter-spacing: 0.2em;
				color: $white;
				font-weight: 600;
      }
      > .layer {
        width: calc(100% - 40px);
      }
    }
    .secondary {
			border-top: 1px solid;
			background: $white;
      > .item {
				display: flex;
				align-items: center;
				padding: 6px 0;
				text-align: left;
				font-size: 14px;
				&.name {
					padding: 0 4px;
					width: 140px;
				}
				&.related {
					padding: 0 4px;
					width: 55px;
					justify-content: center;
				}
				&.unit {
					padding: 0 4px;
					width: 60px;
				}
				&.source {
					padding: 0 4px;
					width: 115px;
				}
				&.description {
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					padding: 0 4px;
					width: calc(100% - 370px);
				}
				p {
					font-size: 14px;
				}
      }
		}
  }
}
.svi-toggle {
	border-radius: 4px;
	color: $white;
	&.-white {
		background: $white;
		color: $blue;
		.controller {
			background: $white;
			.text {
				color: $black;
			}
		}
	}
  .controller {
		display: flex;
		justify-content: space-between;
		border-radius: 4px;
		background: $blue;
		padding: 18px 20px;
		width: 100%;
		height: 60px;
		text-align: left;
		color: inherit;
		.open {
			display: block;
		}
		.close {
			display: none;
		}
		&.-open {
			.open {
				display: none;
			}
			.close {
				display: block;
			}
		}
		.text {
			font-weight: 600;
			@include for-size(phone) {
				font-size: 17px;
			}
			@include for-size(tablet) {
				font-size: 20px;
			}
		}
		.icon {
			span {
				font-size: 16px;
			}
			i {
				margin-left: 12px;
				font-size: 20px;
			}
		}
  }
  .content {
		border-radius: 0 0 4px 4px;
		padding-top: 0;
		max-height: 0;
		font-size: 18px;
		line-height: 1.5;
		transition: all .3s ease;
		overflow: hidden;
		background: $white;
		color: $black;
		> p {
			padding: 18px 20px;
			.sm {
				font-size: 12px;
			}
		}
		&.-show {
			max-height: 3000px;
		}
		.table {
			overflow: auto;
		}
  }
}
.svi-download {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #4B4B4B;
	padding: 8px 20px;
	width: 100%;
	.text {
		font-weight: 500;
		text-align: left;
		line-height: 1.5em;
		@include for-size(phone) {
			font-size: 18px;
		}
		@include for-size(tablet) {
			font-size: 20px;
		}
	}
	.icon {
		display: block;
		border: 1px solid $blue;
		border-radius: 4px;
		padding: 6px 10px;
		background: $white;
		color: $black;
		font-size: 16px;
		white-space: nowrap;
		i {
			margin-left: 12px;
		}
	}
}
.svi-list {
  font-size: 18px;
	line-height: 1.75;
	&.-white {
		li:before {
			color: $white;
		}
	}
  li {
    position: relative;
    margin: 40px 0;
		counter-increment: counter;
		@include for-size(phone) {
			padding-left: 0;
		}
		@include for-size(tablet) {
			padding-left: 80px;
		}
    &:before {
      content: "0" counter(counter);
      font-family: "Roboto";
      font-size: 40px;
      font-weight: 500;
      font-style: italic;
      line-height: 1;
      letter-spacing: 2.5px;
			color: $blue;
			@include for-size(phone) {
				display: block;
				margin-bottom: 8px;
	      font-size: 32px;
			}
			@include for-size(tablet) {
				position: absolute;
				top: 0;
				left: 0;
	      font-size: 40px;
			}
    }
  }
  .label {
    margin-bottom: 8px;
    font-weight: 800;
  }
}
.scroll-spy {
	position: fixed;
	top: 50%;
	right: 20px;
	z-index: 1;
	text-align: right;
	transform: translateY(-50%);
	@include for-size(phone) {
		display: none;
	}
	@include for-size(tablet) {
		display: block;
	}
	.item {
		clear: both;
		float: right;
		display: block;
		margin: 4px 0;
		cursor: pointer;
		&.-show, &:hover {
			&:after {
				background: $black;
			}
			span {
				display: inline-block;
			}
		}
		&:after {
			content: "";
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
			border: 1px solid $black;
			border-radius: 50%;
			width: 6px;
			height: 6px;
		}
		span {
			display: none;
			vertical-align: middle;
			font-size: 15px;
		}
	}
}
.svi-articlelist {
	> .container {
		margin: 0 auto;
		width: 780px;
		max-width: 100%;
	}
  .sectiontitle {
		margin-bottom: 20px;
    border-bottom: 4px solid $blue;
    padding: 15px 0;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 2px;
    text-align: center;
  }
}