.title-block {
	> .ch {
		margin-bottom: 8px;
		font-weight: 600;
		letter-spacing: 2px;
	}
	> .en {
		font-weight: 900;
		letter-spacing: 2px;
	}
}
.subtitle-block {
	padding-bottom: 15px;
	> .ch {
		margin-bottom: 8px;
		font-weight: 600;
		letter-spacing: 1px;
	}
	> .en {
		font-weight: 800;
		letter-spacing: 1px;
	}
}