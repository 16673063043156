@import "../../../styles/variables";
.svi-datatitle {
  padding: 8px 16px;
  font-size: 18px;
}
.svi-datatable {
  width: 100%;
  tr {
    border-bottom: 1px solid hsl(0, 0%, 90%);
    &:first-child {
      border-top: 1px solid hsl(0, 0%, 90%);
    }
  }
  td {
    padding: 8px 16px;
    font-family: 'Roboto Mono';
    font-size: 15px;
    white-space: nowrap;
    &:not(:first-child) {
      text-align: right;
    }
    div{
      display: inline-block;
    }
    .arrow{ 
      display: inline-block;
      margin-top: 5px;
      height: 12px;
      width: 15px;
    }
  }
}
